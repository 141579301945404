<div class="profile-nav-main-container">
  <div class="profile-nav-container" [class.scrolled]="isScrolled">
    <div class="profile-nav-div">
      <h2 *ngIf="!isAdmin">Profile</h2>
      <div class="admin-part">
        <div><h2 *ngIf="isAdmin">Registered Members</h2></div>
        <div><section class="padd18px">
          <div class="example-button-row">
            <button mat-raised-button class="export-btn" (click)="onExport()">export</button>
          </div>
        </section></div>
        <div class="search-container" *ngIf="isAdmin">
          <mat-form-field>
          <mat-label>Search</mat-label>
          <input matInput placeholder="search" (input)="onSearch($event)" #input>
          <button mat-icon-button aria-label="Search" class="search-btn">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field></div>
      </div>
  <ul class="nav-items" *ngIf="!isProfileSummaryPage && !isFPOReportPage && !isAdmin">
    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [ngClass]="{ 'completed': completedSegments[0] }"><a routerLink="/fpo-profile/fpo-details/{{userId}}">FPO Details</a></li>
    <li routerLinkActive="active" [ngClass]="{ 'completed': completedSegments[1] }"><a routerLink="/fpo-profile/contact-details/{{userId}}">Contact Details</a></li>
    <li routerLinkActive="active" [ngClass]="{ 'completed': completedSegments[2] }"><a routerLink="/fpo-profile/location-details/{{userId}}">Location Details</a></li>
    <li routerLinkActive="active" [ngClass]="{ 'completed': completedSegments[3] }"><a routerLink="/fpo-profile/financial-details/{{userId}}">Financial Details</a></li>
    <li routerLinkActive="active" [ngClass]="{ 'completed': completedSegments[4] }"><a routerLink="/fpo-profile/crops-produce/{{userId}}">Crops/Produce</a></li>
    <ng-template [ngIf]="completedSegments[0] || completedSegments[1] || completedSegments[2] || completedSegments[3] || completedSegments[4]">
      <li routerLinkActive="active"><a routerLink="/fpo-profile/profile-summary/{{userId}}">View Profile</a></li>
    </ng-template>
  </ul>
    </div>
  <div class="divider" *ngIf="!isProfileSummaryPage && !isFPOReportPage"><hr></div>
  </div>

</div>
