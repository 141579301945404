<div id="spinLoader">
  <app-loader *ngIf="isLoading"></app-loader>
</div>
<div class="auth-background-image"></div>
  <div class="auth-div">
    <div class="logo"><img src="../../../assets/logo.svg"></div>
<div class="verify-account-container" *ngIf="!isLoading">
  <div class="text-container">
    <h1 class="title">Verify Account</h1>
  <div class="sub-text-container">
    <h3 class="text">Account activation link has been shared on the email you provided. </h3>
  <h3 class="instruction-text">Please click on verify email like to log in.</h3>
  </div>
  </div>
  <img id="mail" src="../../../assets/mail.svg" alt="mail">
  <div class="email-send-text">
    <span>Didn't get an email?<a (click)="onResendVerificationLink()">Send it Again</a>
      |<a routerLink="/auth/login">SIGN IN</a>
    </span>
  </div>
  </div>
  </div>
