<div id="dashboard-charts">
  <!-- First row with two charts -->
  <div class="chart-row">
    <div class="chart-container" id="donut-chart-container" #donutChartContainer><h2>Gender Ratio</h2></div>
     <div class="chart-container" id="license-chart-container" #licenseChartContainer><h2>Licenses</h2></div>
  </div>

  <!-- Second row with one chart -->
  <div class="chart-col">
    <div class="chart-container" id="crops-donut-chart-container" #cropsDonutChartContainer><h2>Crops</h2></div>
  </div>
</div>
