import { Component, ViewChild } from '@angular/core';
import { LoginResponse } from '../loginResponse.interface'
import { AuthService } from '../auth.service'
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  isValidCredentials: boolean = true
  isAdmin : boolean = false
  error: string = ""
  isLoading: boolean = false
  constructor(private router: Router, private authServ: AuthService) {}
  ngOnInit(): void {
    const userId = window.localStorage.getItem('userId');
    const isAdmin = window.localStorage.getItem('isAdmin');
    if(isAdmin && userId)
      this.router.navigate(['/dashboard'])
    else if(userId){
      this.router.navigate(['/fpo-profile/fpo-details/',+userId])
    }
  }
  onLogIn(login: NgForm) {
    const loginDetail = login.value;
    this.isLoading = true
    this.authServ
      .logIn(loginDetail.email, loginDetail.password)
      .subscribe({
        next:(resData: any)=>{
          console.log(resData);
          const tokenObj={
            token: resData.data.token,
            expiresIn: Date.now()+(5*60*1000)
          }
          this.isAdmin = resData.data.isAdmin;
          localStorage.setItem('Authorization',JSON.stringify(tokenObj));
          localStorage.setItem('userId',resData.data.userId.toString() );
          if(resData.data.isAdmin)
          localStorage.setItem('isAdmin',new Boolean(resData.data.isAdmin).toString());
          this.isValidCredentials=true
          this.isLoading = false
          if(this.isAdmin)
          this.router.navigate(['/dashboard'])
          else
          this.router.navigate(['/fpo-profile/fpo-details',resData.data.userId])
        },
        error:err=> {
          console.log(err)
          this.isValidCredentials=false
          this.error = err.error.message
          this.isLoading = false
        }
  });
  }
}
