import { Component, OnInit, ViewChild } from '@angular/core';
import { FpoProfileService } from '../fpo-profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-fpo-details',
  templateUrl: './fpo-details.component.html',
  styleUrls: ['./fpo-details.component.css']
})
export class FpoDetailsComponent implements OnInit{
  @ViewChild('fpoDetail') fpoDetail!: NgForm;

  completedFormSegments: boolean[] = []
  fpoDetailData: {[k: string]: any} = {};
  isErrorFound: boolean = false
  errorMessage: string = ''
  constructor(private fpoProfileServ: FpoProfileService, private route: ActivatedRoute,  private router: Router){}
  onClickNext(fpoDetail: NgForm){
    const fpoDetails = fpoDetail.value
    const userId = this.route.snapshot.paramMap.get('userId');
    
    if(userId!==null){
        this.fpoProfileServ.saveFpoDetails(parseInt(userId), fpoDetails.cinRegNo,
        fpoDetails.fssaiNo,
        fpoDetails.gstNo,
        fpoDetails.riCbNo,
        fpoDetails.totalLand,
        fpoDetails.villageCount,
        fpoDetails.totalSharehoder,
        fpoDetails.womenStakholders,
        fpoDetails.fpoDetailId,
    ).subscribe({
      next: responseData=> {
        
        const segmentIndex = 0; // Replace with the appropriate segment index
        this.completedFormSegments[segmentIndex] = true;
        // Save the completion status in Local Storage with a unique key
        const localStorageKey = `completedFormSegments_${segmentIndex}`;
        localStorage.setItem(localStorageKey, JSON.stringify(this.completedFormSegments[segmentIndex]));

        this.router.navigate([`/fpo-profile/contact-details/${userId}`])
      },
      error: err=> {
        console.log(err)
        this.isErrorFound = true
        this.errorMessage = err
      }
    })
    }
  }
  ngOnInit(): void {
    const userId = this.route.snapshot.paramMap.get('userId');

  //   // Retrieve completion status from Local Storage
  // const localStorageData = localStorage.getItem('completedFormSegments');
  // if (localStorageData) {
  //   this.fpoProfileServ.completedFormSegments = JSON.parse(localStorageData);
  // }

    if(userId!==null){
      this.fpoProfileServ.fetchFpoDetails(parseInt(userId)
      ).subscribe({
        next: (responseData : any) => {
          this.fpoDetailData = responseData;
        },
        error: err=> {console.log(err)
        this.errorMessage = err
      }
      })
    }

  }
}

