import { Component } from '@angular/core';

@Component({
  selector: 'app-email-verification-fail',
  templateUrl: './email-verification-fail.component.html',
  styleUrls: ['./email-verification-fail.component.css']
})
export class EmailVerificationFailComponent {

}
