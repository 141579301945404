<div class="fpo-profile-container">
  <div id="map" style="height: 400px; z-index: 2;" [class.hidden]="!mapVisible"></div>
  <div class="error-container" *ngIf="isErrorFound">{{errorMessage}}</div>
  <form #locationDetails="ngForm" (ngSubmit)="onClickNext(locationDetails)">
    <!-- <div class="client-side-error-container" style="padding-bottom: 1rem;" *ngIf="locationDetails.invalid">
      <div *ngIf="locationDetails.controls.location.invalid && locationDetails.controls.location.errors?.required && (locationDetails.controls.location.dirty || locationDetails.controls.location.touched)">
        Location field is required.
      </div>
      <div *ngIf="locationDetails.controls.pincode.invalid && locationDetails.controls.pincode.errors?.required && (locationDetails.controls.pincode.dirty || locationDetails.controls.pincode.touched)">
        Pincode field is required.
      </div>
      <div *ngIf="locationDetails.controls.pincode.invalid && locationDetails.controls.pincode.errors?.pattern">
        Please enter valid Pincode.
      </div>
      <div *ngIf="locationDetails.controls.district.invalid && locationDetails.controls.district.errors?.required && (locationDetails.controls.district.dirty || locationDetails.controls.district.touched)">
        District field is required.
      </div>
      <div *ngIf="locationDetails.controls.state.invalid && locationDetails.controls.state.errors?.required && (locationDetails.controls.state.dirty || locationDetails.controls.state.touched)">
        State field is required.
      </div>
      <div *ngIf="locationDetails.controls.block.invalid && locationDetails.controls.block.errors?.required && (locationDetails.controls.block.dirty || locationDetails.controls.block.touched)">
        Block field is required.
      </div>
      <div *ngIf="locationDetails.controls.mandi.invalid && locationDetails.controls.mandi.errors?.required && (locationDetails.controls.mandi.dirty || locationDetails.controls.mandi.touched)">
        Mandi field is required.
      </div>
      <div *ngIf="locationDetails.controls.address.invalid && locationDetails.controls.address.errors?.required && (locationDetails.controls.address.dirty || locationDetails.controls.address.touched)">
        Address field is required.
      </div>
      </div> -->
    <div class="form-part">
      <input type="hidden" [ngModel]="locationData.id" name="fpoLocationId">
      <div id="single-input3" class="input-container">
        <div class="location-input-container">
          <input type="text" [(ngModel)]="locationData.location" name="location" class="single-input" required>
        <span class="floating-label">Location<span style="color: #FF0000;">*</span></span>
        </div>
        <div class="map-btn" (click)="onToggleMap()"><span><i class="fa-solid fa-location-crosshairs" style="color: #595959; padding-left: 5px; padding-top: 4px;"></i></span></div>

      </div>
      <div id="single-input1" class="input-container">
        <input type="number" name="pincode" class="single-input" [(ngModel)]="locationData.pincode" pattern="^\d{6}$" required>
        <span class="floating-label">Pincode<span style="color: #FF0000;">*</span></span>
      </div>
      <div class="location-subpart">
        <div class="input-container">
          <input type="text" name="district" [(ngModel)]="locationData.district" required>
          <span class="floating-label">District<span style="color: #FF0000;">*</span></span>
        </div>
        <div class="input-container">
          <input type="text" name="state" [(ngModel)]="locationData.state" required>
          <span class="floating-label">State<span style="color: #FF0000;">*</span></span>
        </div>
        <div class="input-container">
          <select name="state" [(ngModel)]="locationData.state" required>
            <option value="" disabled>State</option>
            <option *ngFor="let state of stateItems" [ngValue]="state.state_name">{{ state.state_name }}</option>
          </select>
          <span class="floating-label">State<span style="color: #FF0000;">*</span></span>
        </div>
        <div class="input-container">
          <input type="text" [(ngModel)]="locationData.block" name="block" required>
          <span class="floating-label">Block<span style="color: #FF0000;">*</span></span>
        </div>
        </div>
      <div id="single-input2" class="input-container">
        <input type="text" name="mandi" [(ngModel)]="locationData.mandi" class="single-input" required>
        <span class="floating-label">Mandi<span style="color: #FF0000;">*</span></span>
      </div>
      <div id="textarea-container" class="input-container">
        <textarea type="text" [(ngModel)]="locationData.address" name="address" required></textarea>
        <span class="floating-label">Address<span style="color: #FF0000;">*</span></span>
      </div>
      </div>
    <div><button type="submit" name="next" id="next-btn">NEXT</button></div>
    </form>
  <div class="background-container">
    <div class="background-image" [style.backgroundImage]="'url(../../../../../assets/location-details-bg-img.svg)'"></div>
  </div>
  </div>
