
  <div class="fpo-profile-container">
    <div class="background-container">
      <div class="background-image" [style.backgroundImage]="'url(../../../assets/fpo-details-background-img.svg)'"></div>
    </div>
    <div class="error-container" *ngIf="isErrorFound">{{errorMessage}}</div>
    <form method="post" id="fpo" enctype="application/x-www-form-urlencoded" action="" #fpoDetails="ngForm" novalidate (ngSubmit)="onClickNext(fpoDetails)">
  <div class="client-side-error-container" style="padding-bottom: 1rem;" *ngIf="fpoDetails.invalid">
    <div *ngIf="fpoDetails.controls.cinRegNo.invalid && fpoDetails.controls.cinRegNo.errors?.required && (fpoDetails.controls.cinRegNo.dirty || fpoDetails.controls.cinRegNo.touched)">
      CIN/R No. field is required.
    </div>
    <div *ngIf="fpoDetails.controls.fssaiNo.invalid && fpoDetails.controls.fssaiNo.errors?.required && (fpoDetails.controls.fssaiNo.dirty || fpoDetails.controls.fssaiNo.touched)">
      FSSAI No. field is required.
    </div>
    <div *ngIf="fpoDetails.controls.gstNo.invalid && fpoDetails.controls.gstNo.errors?.required && (fpoDetails.controls.gstNo.dirty || fpoDetails.controls.gstNo.touched)">
      GST No. field is required.
    </div>
    <!-- <div *ngIf="fpoDetails.controls.gstNo.invalid && fpoDetails.controls.gstNo.errors?.pattern">
      Please enter correct format of GST N0.
    </div> -->
    <div *ngIf="fpoDetails.controls.riCbNo.invalid && fpoDetails.controls.riCbNo.errors?.required && (fpoDetails.controls.riCbNo.dirty || fpoDetails.controls.riCbNo.touched)">
      RI/CB/NO field is required.
    </div>
    <div *ngIf="fpoDetails.controls.totalLand.invalid && fpoDetails.controls.totalLand.errors?.required && (fpoDetails.controls.totalLand.dirty || fpoDetails.controls.totalLand.touched)">
      Total LandUnder FPO field is required.
    </div>
    <div *ngIf="fpoDetails.controls.villageCount.invalid && fpoDetails.controls.villageCount.errors?.required && (fpoDetails.controls.villageCount.dirty || fpoDetails.controls.villageCount.touched)">
      Villages field is required.
    </div>
    <div *ngIf="fpoDetails.controls.totalSharehoder.invalid && fpoDetails.controls.totalSharehoder.errors?.required && (fpoDetails.controls.totalSharehoder.dirty || fpoDetails.controls.totalSharehoder.touched)">
      Total Shareholders field is required.
    </div>
    <div *ngIf="fpoDetails.controls.womenStakholders.invalid && fpoDetails.controls.womenStakholders.errors?.required && (fpoDetails.controls.womenStakholders.dirty || fpoDetails.controls.womenStakholders.touched)">
      Number of women stakeholder field is required.
    </div>
    <!-- <div *ngIf="fpoDetails.controls.gstNo.invalid && fpoDetails.controls.gstNo.errors?.pattern">
      Please enter valid GST No.
    </div> -->
    </div>

<div class="input-container">
  <input class="fpo-name" name="fpo name" type="text" value="{{ fpoDetailData.data?.fpoUser?.FPO_name }}" disabled>
   <!-- <span class="floating-label">FPO Name<span style="color: #FF0000;">*</span></span> -->
</div>
<input type="hidden" [ngModel]="fpoDetailData.data?.fpoDetails?.id" name="fpoDetailId">
<div class="input-container">

  <input class="cin" [ngModel]="fpoDetailData.data?.fpoDetails?.cin_reg_no" name="cinRegNo"  required>
  <span class="floating-label">CIN/R No.<span style="color: #FF0000;">*</span></span>
</div>
<div class="input-container">
  <input class="fssai" [ngModel]="fpoDetailData.data?.fpoDetails?.fssai_no" name="fssaiNo" required>
  <span class="floating-label">FSSAI No.<span style="color: #FF0000;">*</span></span>
</div>
<!--regex for gstno pattern="^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1})$" -->
<div class="input-container">
  <input class="gst" [ngModel]="fpoDetailData.data?.fpoDetails?.gst_no" name="gstNo" required>
  <span class="floating-label">GST No.<span style="color: #FF0000;">*</span></span>
</div>
<div class="input-container">
  <input class="ri" [ngModel]="fpoDetailData.data?.fpoDetails?.ri_cb_no" name="riCbNo" required>
  <span class="floating-label">RI/CB/NO <span style="color: #FF0000;">*</span></span>
</div>
<div class="input-container">
  <input class="land" [ngModel]="fpoDetailData.data?.fpoDetails?.total_land" name="totalLand" type="number" required>
  <span class="floating-label">Total land under FPO (in acres)<span style="color: #FF0000;">*</span></span>
</div>
<div class="input-container">
  <input class="villages" [ngModel]="fpoDetailData.data?.fpoDetails?.village_count" name="villageCount" type="number" required>
  <span class="floating-label">Villages<span style="color: #FF0000;">*</span></span>
</div>
<div class="input-container">
  <input class="shareholder" [ngModel]="fpoDetailData.data?.fpoDetails?.total_sharehoder"  name="totalSharehoder" type="number" required>
  <span class="floating-label">Total Shareholders<span style="color: #FF0000;">*</span></span>
</div>
<div class="input-container">
  <input class="women" [ngModel]="fpoDetailData.data?.fpoDetails?.women_stakholders" name="womenStakholders" type="number" required>
  <span class="floating-label">Number of women stakeholder<span style="color: #FF0000;">*</span></span>
</div>
<button name="next" id="next-btn" [disabled]="fpoDetails.invalid">NEXT</button>
  </form>
  </div>
