import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'FrontEnd';
  isFPOProfileSection: boolean = false
  isAuthPage: boolean = false
  constructor(private router: Router){
  }
  ngOnInit(): void {
    this.router.events
    .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      const currentPath = event.url.split('/')[1]; // Get the first segment of the URL
      this.isAuthPage = currentPath === '' || currentPath === 'auth';
    })
    // this.onAuthPageActivated()
    // this.onFPOProfilePage()
  }
  // onAuthPageActivated(){
  //   // const activePage = this.route.snapshot?.url[0]?.path;
  //   const currentUrl = this.router.url;
  //   const urlSegments = currentUrl.split('/');
  //   const activePage = urlSegments[urlSegments.length - 1];
  //   console.log(activePage)
    // return activePage === 'signin' || activePage === 'signup';
  //}
  // onFPOProfilePage(){
  //   this.router.events
  //     .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
  //     .subscribe((event: NavigationEnd) => {
  //       const currentPath = event.url.split('/')[1];
  //       if(currentPath === 'fpo-profile'){
  //         this.isFPOProfileSection = true
  //       }
  // })
  //}
}
