import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface LoginResponse {
  data: {
    userId: number;
    token: string;
  };
  message: string;
  status: boolean;
}

 interface SignUpResponse {
  data: {
    userToken: string;
  };
  message: string;
  status: boolean;
}

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = 'https://api.bharatfpo.org/api/v1/user';
  constructor(private http: HttpClient) { }

    signUp(FPOName: string, contactPersonName: string, mobileNumber: number, email: string, password: string, confirmPassword: string){
      const signUpObj = {
        FPOName: FPOName,
        contactPersonName: contactPersonName,
        mobileNumber: mobileNumber,
        email: email,
        password: password,
        confirmPassword: confirmPassword
      }
    return this.http.post<SignUpResponse>(`${this.baseUrl}/registration`,signUpObj)
    }

    emailVerification(userId: number, verificationToken: string){
      const emailVerificationDetail = {
        userId,
        verificationToken
      }
      console.log(emailVerificationDetail)
      return this.http.post(`${this.baseUrl}/email-verification`, emailVerificationDetail)
    }

    resendVerificatinLink(userToken: string){
      const userTokenObj = {
        userToken
      }
      return this.http.post(`${this.baseUrl}/resend-verification-link`, userTokenObj)
    }
    logIn(email: string, password: string){
      const loginObj = {
        email: email,
        password: password
      }
      return this.http.post<LoginResponse>(`${this.baseUrl}/login`, loginObj)
    }

    requestPasswordReset(email: string){
      const emailObj = {
        email: email
      }
      return this.http.post(`${this.baseUrl}/request-reset-password`, emailObj)
    }
    // compareResetPasswordToken(resetPasswordToken: string){
    //   return this.http.post(`${this.baseUrl}/change-password`, resetPasswordToken)
    // }
    changePassword(newPassword: string, confirmNewPassword: string, resetPasswordToken: string){
      const passwordObj = {
        newPassword,
        confirmNewPassword,
        resetPasswordToken
      }
      return this.http.post(`${this.baseUrl}/change-password`, passwordObj)
    }
  }
