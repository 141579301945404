<div class="main-container">

<div class="container" [class.scrolled]="isScrolled" [class.profilePage]="isProfilePage" [class.nonProfilePage]="!isProfilePage">
  <!-- <div id="logo-1" routerLink="/auth/login" *ngIf="isAuthPage"><img src="../../../assets/logo.svg"></div> -->
  <div id="logo-2" routerLink="/auth/login"><img src="../../assets/Logo-green.svg"></div>

  <div class="menu-profile-container">
    <div id="logout"><app-logout></app-logout></div>
    <div id="user-profile-img"><img src="../../assets/dummy-profile-img.webp"></div>
  <div class="menu-container">
    <div id="menu" (mouseenter)="onHoverMenuButton()">
      <div class="menu-line"></div>
      <div class="menu-line"></div>
      <div class="menu-line"></div>
    </div>
      <div class="menu-items" (mouseleave)="onLeaveMenuButton()" *ngIf="areMenuItemsShown && isAdmin">
        <ul>
          <li routerLink="/dashboard">Dashboard</li>
          <li routerLink="/admin/registered-fpo-report">FPO List</li>
        </ul>
      </div>
  </div>
  </div>

  </div>
  <!-- <div class="container2" [class.scrolled]="isScrolled" [style.background-color]="!isAuthPage?'#F9FFE3':''">
  <div id="logo-1" routerLink="/auth/login" *ngIf="isAuthPage"><img src="../../../assets/logo.svg"></div>
  <div id="logo-2" routerLink="/auth/login" *ngIf="!isAuthPage"><img src="../../assets/Logo-green.svg"></div>

  <div class="menu-profile-container" *ngIf="!isAuthPage">
    <div id="logout" *ngIf="!isAuthPage"><app-logout></app-logout></div>
    <div id="user-profile-img"><img src="../../assets/dummy-profile-img.webp"></div>
  <div id="menu">
    <div class="menu-line" [style.background-color]="!isAuthPage?'#7FC800':''"></div>
    <div class="menu-line" [style.background-color]="!isAuthPage?'#7FC800':''"></div>
    <div class="menu-line" [style.background-color]="!isAuthPage?'#7FC800':''"></div>
  </div>
  </div>

  </div> -->
  <!-- <div class="horizontal-line" [class.scrolled]="isScrolled"></div> -->

</div>
