import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {
  @ViewChild('signup', {static: false}) signup!: NgForm
  isSignedUp: boolean = false
  isErrorFound: boolean = false
  errorMessage: string = ''
  isLoading : boolean = false
  FPOName: string = '';
  contactPersonName: string = '';
  mobileNumber: number = 0;
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  formSubmitted= false;
  constructor(private authServ: AuthService, private router: Router){}
  ngOnInit(): void {
    // const userId = window.localStorage.getItem('userId');
    // if(userId){
    //   this.router.navigate(['/fpo-profile/fpo-details/',+userId])
    // }
  }
  onSignUp(signUp: NgForm){
    const adminDetail = signUp.value;

    this.isLoading = true
    this.authServ.signUp(
        adminDetail.FPOName,
        adminDetail.contactPersonName,
        adminDetail.mobileNumber,
        adminDetail.email,
        adminDetail.password,
        adminDetail.confirmPassword
    ).subscribe({
    next: responseData=>{
        console.log(responseData)
        this.isSignedUp=true
        this.router.navigate(['/auth/verify-account'])
        this.isLoading = false
      },
      error: err=>{
        this.isErrorFound = true
        this.errorMessage = err.error.message
        this.isLoading = false
      }
    })
  }
}
