import { Component, OnInit, HostListener } from '@angular/core';
import { FpoProfileService } from '../../FPO-Profile/fpo-profile.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { AdminService } from 'src/app/admin/admin.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-fpo-profile-navbar',
  templateUrl: './fpo-profile-navbar.component.html',
  styleUrls: ['./fpo-profile-navbar.component.css']
})
export class FpoProfileNavbarComponent implements OnInit {
  userId : any
  isScrolled: boolean = false
  isNavchanged: boolean = false
  completedSegments: boolean[] = []
  isProfileSummaryPage: boolean = false
  isFPOReportPage: boolean = false
  isAdmin:any = false

  constructor(private fpoProfileServ: FpoProfileService, private adminServ: AdminService, private sharedServ: SharedService, private router: Router){
    this.fpoProfileServ.isProfileSummaryPage$.subscribe((value) => {
      this.isProfileSummaryPage = value;
    });
    this.adminServ.isFPOReportPage$.subscribe((value) => {
      this.isFPOReportPage = value;
    });

    // this.router.events
    // .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   const currentPath = event.url.split('/')[2];
    //   this.isFPOReportPage = currentPath === 'registered-fpo-report';
    // });
  }
  @HostListener('window:scroll', [])
onScroll(): void {
  const navbarHeight = 74; // Adjust this value to match your actual navbar height
  const scrollY = window.scrollY;
  // this.isFixed = scrollY>0
  this.isScrolled = scrollY > 90;
  // this.isFixed = scrollY > navbarHeight;

}

  ngOnInit(): void{
    console.log(this.isFPOReportPage);
    this.userId = window.localStorage.getItem('userId');
    this.isAdmin = window.localStorage.getItem('isAdmin');
      this.fpoProfileServ.fetchProfileCompletion(parseInt(this.userId)
      ).subscribe({
        next: (responseData : any) => {
          console.log(responseData)
          this.completedSegments = [
            responseData.data.isDetail,
            responseData.data.isLocation,
            responseData.data.isContact,
            responseData.data.isCropDetail,
            responseData.data.isFinanceDetail];

        },
        error: err=> {console.log(err)
      }
      })
    // Loop through the segment indices and retrieve completion status from Local Storage
    // for (let segmentIndex = 0; segmentIndex < 4; segmentIndex++) {
    //   const localStorageKey = `completedFormSegments_${segmentIndex}`;
    //   const completed = JSON.parse(localStorage.getItem(localStorageKey) || 'false');
    //   this.completedSegments.push(completed);
    // }

    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentPath = event.url.split('/')[2]; // Get the first segment of the URL
        this.isProfileSummaryPage = currentPath === '' || currentPath === 'profile-summary';
console.log(currentPath)
  });
//   console.log('hi')
  }

  onSearch(event: any) {
    const query = event?.target?.value || '';
    this.sharedServ.setSearchQuery(query);
  }
  onExport(){
    this.sharedServ.setExportClick();
  }
}
