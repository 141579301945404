import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent {
  isLoading: boolean = false
  constructor(private route: ActivatedRoute, private router: Router, private authServ: AuthService) { }
  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('confirmation-token');
    const userId = parseInt(this.route.snapshot.queryParamMap.get('userId')??'');
    this.isLoading = true
    // Use the token for further processing
    if(token){
      this.authServ.emailVerification(userId,token).subscribe({
        next: ()=>{
          this.router.navigate(['/auth/email-verification-success'])
          this.isLoading = false
        },
      error: error=>{
        this.router.navigate(['/auth/email-verification-fail'])
        this.isLoading = false
      }
    })
    }
  }

}
