import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FpoDetailsComponent } from './fpo-details/fpo-details.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { FinancialDetailsComponent } from './financial-details/financial-details.component';
import { CropsComponent } from './crops/crops.component';
import { FpoRoutingModule } from './fpo-profile.routing.module';
import { FpoProfileComponent } from './fpo-profile.component';
import { ProfileSummaryComponent } from './profile-summary/profile-summary.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    FpoDetailsComponent,
    ContactDetailsComponent,
    LocationDetailsComponent,
    FinancialDetailsComponent,
    CropsComponent,
    FpoProfileComponent,
    ProfileSummaryComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    FpoRoutingModule,
    SharedModule
  ],
  exports: [
    FpoDetailsComponent,
    ContactDetailsComponent,
    LocationDetailsComponent,
    FinancialDetailsComponent,
    CropsComponent,
    FpoProfileComponent,
    ProfileSummaryComponent
  ]
})
export class FpoProfileModule { }
