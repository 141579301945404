<mat-card>
<div class="statistics-container">
  <div id="total-fpos" class="stat-content">
    <div class="img-container"><img src="../../../assets/fpos.png"></div>
    <div class="info-container" routerLink="/admin/registered-fpo-report">
      <h3>Total FPOs</h3>
    <h2>{{ analysisData.totalFpos }}</h2>
    </div>
  </div>
  <div id="farmers" class="stat-content">
    <div class="img-container1"><img src="../../../assets/farmers.svg"></div>
    <div class="info-container">
      <h3>Farmers</h3>
    <h2>{{ analysisData.totalFarmers }}</h2>
    </div>
  </div>
  <div id="total-crops" class="stat-content">
    <div class="img-container1"><img src="../../../assets/crops.svg"></div>
    <div class="info-container">
      <h3>Total Crops</h3>
    <h2>{{ analysisData.totalCrops }}</h2>
    </div>
  </div>
</div>
</mat-card>
