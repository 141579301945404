import { Component, ViewChild, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FpoProfileService } from '../fpo-profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as L from 'leaflet';

interface LatLng {
  lat: number;
  lng: number;
}
interface State {
  id: number;
  state_name: string;
 }

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.css']
})
export class LocationDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('locationDetails') locationDetails!: NgForm

  completedFormSegments: boolean[] = []
  stateItems: State[] = []
  mapVisible = false
  isErrorFound: boolean = false
  errorMessage:  string = ''
  private map: any;
  private marker: any;
  private defaultLocation: L.LatLngExpression  = [28.6139, 77.2090]; // Default coordinates
  private selectedLocation: LatLng = { lat: 0, lng: 0 };

  userId = this.route.snapshot.paramMap.get('userId');
  locationData: {[k: string]: any} = {}
  oldFormData = {}
  
  constructor(private fpoProfileServ: FpoProfileService, private route: ActivatedRoute,  private router: Router){}

  ngOnInit(): void {
    this.fpoProfileServ.fetchStates()
    .subscribe({
      next: (statesData: any)=>{
          const statesArr = statesData.data
          statesArr.forEach((state: { id: any; state_name: any; })=>{
          const stateDataObj: State = {
            id: state.id,
            state_name: state.state_name
          }

          this.stateItems.push(stateDataObj)
        })
      }
    });
    if(this.userId!==null){
      this.fpoProfileServ.fetchLocation(parseInt(this.userId)
      ).subscribe({
        next: (responseData : any) => {
          this.locationData = responseData.data?? null;
           this.oldFormData = {
            address: this.locationData.address,
            block: this.locationData.block,
            district: this.locationData.district,
            location: this.locationData.location,
            mandi: this.locationData.mandi,
            pincode: this.locationData.pincode,
            state: this.locationData.state,
            state_id : this.locationData.state_id
          }
        },
        error: err=> {console.log(err)
        this.errorMessage = err
      }
      })
    }
  }
  ngAfterViewInit() {
    console.log('ngAfterViewInit called');
    // this.initMap();
  }

  ngOnDestroy() {
    this.destroyMap();
  }

   deepComparison(obj1: any, obj2: any) {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepComparison(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  formIsNotUpdated(latestlocationDetails: any): boolean{
    return this.deepComparison(this.oldFormData, latestlocationDetails)
   }

  onClickNext(locationDetails: NgForm){
    const locationData = locationDetails.value

    if(!this.formIsNotUpdated(locationData)){
      if(this.userId!==null){
        this.fpoProfileServ.saveLocationDetails(parseInt(this.userId), locationData.pincode, locationData.district, locationData.state, locationData.block, locationData.mandi, locationData.address, locationData.location, locationData.fpoLocationId).subscribe({
        next: responseData=>{

          const segmentIndex = 2; // Replace with the appropriate segment index
          this.completedFormSegments[segmentIndex] = true;
          // Save the completion status in Local Storage with a unique key
          const localStorageKey = `completedFormSegments_${segmentIndex}`;
          localStorage.setItem(localStorageKey, JSON.stringify(this.completedFormSegments[segmentIndex]));
          this.router.navigate([`/fpo-profile/financial-details/${this.userId}`])
        },
        error: err=>{
          console.log(err)
          this.isErrorFound = true
          this.errorMessage = err
        }
        })
      }
    }else{
      this.router.navigate([`/fpo-profile/financial-details/${this.userId}`])
    }
  }
  private initMap() {
    console.log('map initialization...')
    // Initialize the map
    this.map = L.map('map').setView(this.defaultLocation,7.5); // 4 is zoom value

    // Add a tile layer
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19
    }).addTo(this.map);

    // Add a marker
    this.marker = L.marker([28.6139, 77.2090], { draggable: true })
    .addTo(this.map)
    .on('dragend', this.onMarkerDragEnd.bind(this));

    // Add a click event listener to the map
  this.map.on('click', (event: L.LeafletMouseEvent) => {
    this.onMapClick(event);
  });
  this.map.invalidateSize();
  }

  private onMarkerDragEnd(event: any) {
    const marker = event.target;
    const position = marker.getLatLng();

    // Update the marker's position
    this.marker.setLatLng(position);
  }

  private destroyMap() {
    if (this.map) {
      this.map.remove();
      this.map = null;
      this.marker = null;
    }
  }

  onToggleMap(){

    this.mapVisible = !this.mapVisible;
  if (this.mapVisible) {
    this.initMap();
  } else {
    this.destroyMap();
  }
  // Adjust the map's size to ensure proper rendering
  setTimeout(() => {
    if (this.map) {
      this.map.invalidateSize();
    }
  }, 0);
  }

  onMapClick(event: L.LeafletMouseEvent) {
    this.selectedLocation = { lat: event.latlng.lat, lng: event.latlng.lng };

    // Update the marker's position
    this.marker.setLatLng(this.selectedLocation);

    // Fetch address details using Nominatim reverse geocoding
    fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.selectedLocation.lat}&lon=${this.selectedLocation.lng}`)
      .then(response => response.json())
      .then(data => {
        // Extract address details
        const address = data.address;
        // Update the location input field with the selected coordinates and address details
        const locationInput = this.locationDetails.form.get('location');
        const pincodeInput = this.locationDetails.form.get('pincode');
        const stateInput = this.locationDetails.form.get('state');
        const districtInput = this.locationDetails.form.get('district');
        const blockInput = this.locationDetails.form.get('block');
        const mandiInput = this.locationDetails.form.get('mandi');
        const addressInput = this.locationDetails.form.get('address');

        if (locationInput && locationInput !== undefined && pincodeInput && pincodeInput !== undefined && stateInput && stateInput !==undefined  && districtInput && districtInput !== undefined && blockInput !== null && mandiInput !== null && addressInput !== null) {
          locationInput.setValue(`${this.selectedLocation.lat}, ${this.selectedLocation.lng}`)
          pincodeInput.setValue(address.postcode ?? '')
           stateInput.setValue(address.state ?? '')
           districtInput.setValue(address.state_district ?? '')

           blockInput.setValue(``)
           mandiInput.setValue(``)
           addressInput.setValue(``)
        }
      })
      .catch(error => {
        console.error('Error fetching address:', error);
      });
  }



}
