import { Component, OnInit, ChangeDetectorRef, HostListener,ViewChild, ElementRef } from '@angular/core';
 import { MatTableDataSource } from '@angular/material/table';
import {MatTableModule} from '@angular/material/table';
import { SharedService } from 'src/app/shared/shared.service';
import { AdminService } from '../admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as XLSX from 'xlsx';
//import { AdminService } from '../admin.service';
// import { Report } from '../report.model';

export interface reportElement{
  userId?:number,
  fpoName: string,
  email?:string,
  entryYear? :string,
  district?: string,
  state?: string,
  chairman ?: string,
  phone ?: string,
  villages ?: number,
  totalShareholders ?: number,
  turnover ?: number
}

@Component({
  selector: 'app-fpo-report-tabular',
  templateUrl: './fpo-report-tabular.component.html',
  styleUrls: ['./fpo-report-tabular.component.css']
})
export class FpoReportTabularComponent implements OnInit {
  @ViewChild('TABLE') table!: ElementRef;
   searchQuery: any = ''
   fpoMembers: any = []

 ELEMENT_DATA: reportElement[] = [
 {fpoName: 'Usathi Utpadak Producer Company', district: 'Bilaspur', state: 'Punjab'},
 {fpoName: 'Usathi Utpadak Producer Company', district: 'Bilaspur', state: 'Punjab'},
 {fpoName: 'Usathi Utpadak Producer Company', district: 'distt3', state: 'Punjab'},
 {fpoName: 'Usathi Utpadak Producer Company', district: 'Bilaspur', state: 'Uttarakhand'},
 {fpoName: 'Usathi Utpadak Producer Company', district: 'distt5', state: 'Himachal Pradesh'},
 { fpoName: 'Usathi Utpadak Producer Company', district: 'distt6', state: 'Punjab'},
 {fpoName: 'Usathi Utpadak Producer Company', district: 'distt7', state: 'Punjab'}];
dataSource = new MatTableDataSource(this.ELEMENT_DATA)
  errorMessage: any;
constructor(private sharedServ: SharedService,private adminService: AdminService,
  private route: ActivatedRoute,  private router: Router,private changeDetectorRefs: ChangeDetectorRef){}

displayedColumns: string[] = ['FPO Name', 'Email','Phone No', 'State','District','Entry Year'];

isScrolled: boolean = false
@HostListener('window:scroll', [])
onScroll(): void {
  const navbarHeight = 74; // Adjust this value to match your actual navbar height
  const scrollY = window.scrollY;
  // this.isFixed = scrollY>0
  this.isScrolled = scrollY > 90;
  // this.isFixed = scrollY > navbarHeight;

}
ngOnInit(): void {
  //  this.onShowReport();
  this.refresh();

    this.sharedServ.getSearchQuery().subscribe((query) => {
      this.searchQuery = query;
      this.applyFilter();
    });
    this.sharedServ.getExportClick().subscribe(() => {
      this.ExportTOExcel();
    });
}
applyFilter() {
  this.dataSource.filter = this.searchQuery.trim().toLowerCase();
}

ExportTOExcel()
{
  const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  
  XLSX.utils.book_append_sheet(wb, ws, 'registered-members-');
  
  /* save to file */
  XLSX.writeFile(wb, 'RegisteredMembers.xlsx');
}

refresh() {
  const stateId = this.route.snapshot.queryParams.state_id;
  this.adminService.fetchFpoMembers(stateId).
  subscribe({
      next: (responseData : any) => {        

        if(responseData && responseData.data){
          let respData = responseData.data;
          let arrResult = []
          for(let i=0;i<respData.length;i++){
            const obj = {
              userId: respData[i].id,
              fpoName: respData[i].FPO_name,
              email: respData[i].email,
              mobileNumber : respData[i].mobile_number,
              state :respData[i].state ?? '',
              district: respData[i].district ?? '',
              entryYear: '2023'
            }
            arrResult[i] = obj;
          }
          this.dataSource.data = arrResult;
        }
        
      },
      error: err=> {console.log(err)
      this.errorMessage = err
    }
  })

  
  this.changeDetectorRefs.detectChanges();
}

}
