import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FpoReportTabularComponent } from './fpo-report-tabular/fpo-report-tabular.component';
import { AdminRoutingModule } from './admin.routing.module';
import { AdminComponent } from './admin.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    AdminComponent,
    FpoReportTabularComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AdminRoutingModule,
    SharedModule,
    MatFormFieldModule,
    MatTableModule
  ],
  exports: [
    AdminComponent,
    FpoReportTabularComponent]
})
export class AdminModule { }
