<div class="fpo-profile-container">
  <!-- <div class="error-container" *ngIf="isErrorFound">{{errorMessage}}</div> -->
  <form #financeDetails="ngForm" (ngSubmit)="onClickNext(financeDetails)">
    <div class="client-side-error-container" style="padding-bottom: 1rem;" *ngIf="financeDetails.invalid">
      <div *ngIf="financeDetails.controls.financeYear.invalid && financeDetails.controls.financeYear.errors?.required && (financeDetails.controls.financeYear.dirty || financeDetails.controls.financeYear.touched)">
        FY Field is required.
      </div>
      <div *ngIf="financeDetails.controls.netTurnover.invalid && financeDetails.controls.netTurnover.errors?.required && (financeDetails.controls.netTurnover.dirty || financeDetails.controls.netTurnover.touched)">
        Net Turnover field is required.
      </div>
      <div *ngIf="financeDetails.controls.annualIncomePerMember.invalid && financeDetails.controls.annualIncomePerMember.errors?.required && (financeDetails.controls.annualIncomePerMember.dirty || financeDetails.controls.annualIncomePerMember.touched)">
        Annual Agri Income / Member field is required.
      </div>
      <div *ngIf="financeDetails.controls.revenuePerAcre.invalid && financeDetails.controls.revenuePerAcre.errors?.required && (financeDetails.controls.revenuePerAcre.dirty || financeDetails.controls.revenuePerAcre.touched)">
        Agri Revenue / Acres (Lakhs) field is required.
      </div>
      </div>
    <div class="form-part1">
      <div class="input-container">
        <select name="financeYear" [(ngModel)]="financeData.financeYear">
          <option value="2018-2019">2018-2019</option>
          <option value="2019-2020">2019-2020</option>
          <option value="2020-2021">2020-2021</option>
          <option value="2021-2022">2021-2022</option>
          <option value="2022-2023">2022-2023</option>
      </select>
      <span class="floating-label">FY<span style="color: #FF0000;">*</span></span>
      </div>
    <div class="input-container">
      <input type="number" name="netTurnover" [(ngModel)]="financeData.netTurnover" required>
      <span class="floating-label">Net Turnover<span style="color: #FF0000;">*</span></span>
    </div>


    </div>
    <div class="form-part2">
      <h3>License</h3>
      <label class="block"><input class="checkbox-btn" type="checkbox" name="Seeds" [ngModel]="selectedLicenseArr.includes('Seeds')" value="Seeds">Seeds</label>
      <label class="block"><input class="checkbox-btn" type="checkbox" name="Fertilisers" [ngModel]="selectedLicenseArr.includes('Fertilisers')" value="Fertilisers">Fertilisers</label>
      <label class="block"><input class="checkbox-btn" type="checkbox" name="Pesticides" [ngModel]="selectedLicenseArr.includes('Pesticides')" value="Pesticides">Pesticides</label>

    </div>
    <div class="form-part3">
      <div class="input-container">
        <input type="number" name="annualIncomePerMember" [(ngModel)]="financeData.annualIncomePerMember"  required>
        <span class="floating-label">Annual Agri Income / Member<span style="color: #FF0000;">*</span></span>
      </div>
      <div class="input-container">
        <input type="number" name="revenuePerAcre" [(ngModel)]="financeData.revenuePerAcre" required>
        <span class="floating-label">Agri Revenue / Acres (Lakhs)<span style="color: #FF0000;">*</span></span>
      </div>
    </div>
    <input type="hidden" [ngModel]="financeData.financeDetailId" name="financeDetailId">
    <button type="submit" name="next" id="next-btn" [disabled]="financeDetails.invalid">NEXT</button>
  </form>
  <div class="background-container">
    <div class="background-image" [style.backgroundImage]="'url(../../../../../assets/financial-details-bg-img.svg)'"></div>
  </div>
  </div>
