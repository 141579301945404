
<div class="dashboard-main-container">
  <div class="title-container">
    <div class="title1" [class.scrolled]="isScrolled"><h2>Dashboard</h2></div>
    <!-- <div class="title2" [class.scrolled]="isScrolled"><h2>Dashboard</h2></div> -->
  </div>
    <div class="dashboard-container">

      <div id="fpo-stat-view"><app-fpo-statistics></app-fpo-statistics></div>
        <div class="donut-charts">
          <mat-card>
            <div class="dashboard-part1">
              <div id="country-map-child-container"><app-country-map-chart></app-country-map-chart></div>
            <div id="donut-charts-child-container"><app-donut-charts></app-donut-charts></div>
            </div>
          </mat-card>
        </div>
      <div id="fpos-bar-chart"><app-fpos-bar-chart></app-fpos-bar-chart></div>
      <div id="farmers-bar-chart"><app-farmers-bar-chart></app-farmers-bar-chart></div>
    </div>
</div>

