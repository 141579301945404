import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  isErrorFound: boolean = false
  errorMessage: string = ''
  constructor(private authServ: AuthService, private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar){}
  onChangePassword(changePassword: NgForm){
    const formDetail = changePassword.value
    const resetPasswordToken = this.route.snapshot.queryParamMap.get('reset-password-token');
    console.log(resetPasswordToken)
    if(resetPasswordToken){
    this.authServ.changePassword(formDetail.newPassword, formDetail.confirmNewPassword, resetPasswordToken)
    .subscribe({
      next: (res)=>{
        console.log(res)
        this.router.navigate(['/auth/login'])
        const snackBarConfig: MatSnackBarConfig = {
          duration: 4000,
         verticalPosition: 'top',
         // panelClass: ['custom-snackbar'],
       };
       // Show a notification toast
   this.snackBar.open('Congratulation !! Your password has been reset', 'Close', snackBarConfig);

      },
      error: (err)=>{
        this.isErrorFound = true
        console.log(`error is: ${err.error.message}`)
        this.errorMessage = err.error.message
      }
    })
  }
  }
}
