import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router'
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {
   errorMessage = ''
   constructor(private router: Router) {}
  ngOnInit(): void {  
    const userId = window.localStorage.getItem('userId');
    const isAdmin = window.localStorage.getItem('isAdmin');
    if(isAdmin && userId)
      this.router.navigate(['/registered-fpo-report'])
    else if(userId){
      this.router.navigate(['/fpo-profile/fpo-details/',+userId])
    } else {
      this.router.navigate(['/auth/login'])
    }
  }
}
