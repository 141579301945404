import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { EmailVerificationSuccessComponent } from './email-verification-success/email-verification-success.component';
import { EmailVerificationFailComponent } from './email-verification-fail/email-verification-fail.component';
import { AuthRoutingModule } from './auth-routing.module';
import { RouterModule } from '@angular/router';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { SharedModule } from '../shared/shared.module';
import { AuthComponent } from './auth.component';
import { LogoutComponent } from './logout/logout.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';


@NgModule({
  declarations: [
    AuthComponent,
    SignupComponent,
    LoginComponent,
    LogoutComponent,
    EmailVerificationComponent,
    EmailVerificationSuccessComponent,
    EmailVerificationFailComponent,
    VerifyAccountComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    AuthRoutingModule,
    SharedModule
  ],
  exports: [
    AuthComponent,
    SignupComponent,
    LoginComponent,
    LogoutComponent,
    EmailVerificationComponent,
    EmailVerificationSuccessComponent,
    EmailVerificationFailComponent,
    VerifyAccountComponent,
    ForgetPasswordComponent
  ]
})
export class AuthModule { }
