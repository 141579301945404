import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fpo-profile',
  templateUrl: './fpo-profile.component.html',
  styleUrls: ['./fpo-profile.component.css']
})
export class FpoProfileComponent implements OnInit {
ngOnInit(): void{
  
}
}
