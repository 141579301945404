<!-- <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
     <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sign Up</title>
    <meta name="viewport" content="width=device-width" initial-scale="1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Red+Hat+Display:wght@500&display=swap" rel="stylesheet">

</head>
<body> -->
  <div id="spinLoader">
    <app-loader *ngIf="isLoading"></app-loader>
  </div>
  <div class="auth-background-image" *ngIf="!isLoading"></div>
  <div class="auth-div" *ngIf="!isLoading">
    <div class="logo"><img src="../../../assets/logo.svg"></div>
    <div class="auth-main-container">

    <h1>Register</h1>
    <div class="content"><span>Welcome back to AgriGation - your one stop solution for information on national agriculture statstics</span></div>
    <div class="error-container" *ngIf="isErrorFound || !isSignedUp">{{errorMessage}}</div>
    <div class="auth-form-container">
  <form #signup="ngForm" (ngSubmit)="onSignUp(signup)">
     <!-- Display validation errors at the top of the form -->
  <div class="client-side-error-container" *ngIf="signup.invalid">
    <div *ngIf="signup.controls.FPOName.invalid && signup.controls.FPOName.errors?.required && (signup.controls.FPOName.dirty || signup.controls.FPOName.touched)">
      FPO Name is required.
    </div>
    <div *ngIf="signup.controls.contactPersonName.invalid && signup.controls.contactPersonName.errors?.required && (signup.controls.contactPersonName.dirty || signup.controls.contactPersonName.touched)">
      Contact Person is required.
    </div>
    <div *ngIf="signup.controls.mobileNumber.invalid && signup.controls.mobileNumber.errors?.required && (signup.controls.mobileNumber.dirty || signup.controls.mobileNumber.touched)">
      Contact Number is required.
    </div>
    <div *ngIf="signup.controls.mobileNumber.invalid && signup.controls.mobileNumber.errors?.pattern">
      Please enter valid Mobile Number.
    </div>
    <div *ngIf="signup.controls.email.invalid && signup.controls.email.errors?.required && (signup.controls.email.dirty || signup.controls.email.touched)">
      Email/ID is required.
    </div>
    <div *ngIf="signup.controls.email.invalid && signup.controls.email.errors?.pattern">
      Please enter valid Email Id.
    </div>
    <div *ngIf="signup.controls.password.invalid && signup.controls.password.errors?.required && (signup.controls.password.dirty || signup.controls.password.touched)">
      Password is required.
    </div>
    <div *ngIf="signup.controls.password.invalid && signup.controls.password.errors?.minlength">
      Password must be at least 8 characters long.
    </div>
    <div *ngIf="signup.controls.password.invalid && signup.controls.password.errors?.maxlength">
      Password cannot be longer than 12 characters.
    </div>
    <div *ngIf="signup.controls.password.invalid && signup.controls.password.errors?.pattern">
      Password should have atleast 1 uppercase, 1 lowercase, 1 wild character and 1 number.
    </div>
    <div *ngIf="signup.controls.confirmPassword.invalid && signup.controls.confirmPassword.errors?.required && (signup.controls.confirmPassword.dirty || signup.controls.confirmPassword.touched)">
      Type Password Again is required.
    </div>
    <div *ngIf="signup.controls.confirmPassword.invalid && signup.controls.confirmPassword.errors?.minlength">
      Password must be at least 8 characters long.
    </div>
    <div *ngIf="signup.controls.confirmPassword.invalid && signup.controls.confirmPassword.errors?.maxlength">
      Password cannot be longer than 12 characters.
    </div>
    <div *ngIf="signup.controls.confirmPassword.invalid && signup.controls.confirmPassword.errors?.pattern">
      Password should have atleast 1 uppercase, 1 lowercase, 1 wild character and 1 number.
    </div>
  </div>
    <div class="input-container">
      <input class="fpo" id="fponame" ngModel name="FPOName" required>
      <span class="floating-label">FPO Name<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
    <input class="contact" ngModel name="contactPersonName" required>
      <span class="floating-label">Contact Person<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
    <input class="contact" type="number" ngModel name="mobileNumber" pattern="^[6-9][0-9]{9}$" required>
      <span class="floating-label">Contact Number<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
    <input class="email" id="email" type="email" ngModel name="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$" required>
      <span class="floating-label">Email/ID<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
    <input class="password" id="password" type="password" ngModel name="password" minlength="8" maxlength="12" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$" required>
      <span class="floating-label">Password<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
    <input class="password2" id="password2" type="password" ngModel name="confirmPassword" minlength="8" maxlength="12" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$" required>
      <span class="floating-label">Type Password Again<span style="color: #FF0000;">*</span></span>
    </div>
    <!-- <input class="fpo" id="fponame" name="fponame" placeholder="FPO Name" ngModel name="FPOName" required> -->
  <button id="auth-btn" name="signup" type="submit" [disabled]="signup.invalid"><span class="signup">SIGN UP</span></button>
  </form>
    <div class="auth-container">
      <span class="account">Already a member?<br></span>
    <a id="auth-link" routerLink="/auth/login"><span id="auth-text">SIGN IN</span> </a>
    </div>
  </div>
    </div>
  </div>
<!-- </body>
</html> -->
