import { Component } from '@angular/core';
import { DashboardService } from '../dashboard.service';

interface BarChartData {
  label: string;
  value: number;
  stateName: string;
  farmersPerFpo: number;
}

@Component({
  selector: 'app-farmers-bar-chart',
  templateUrl: './farmers-bar-chart.component.html',
  styleUrls: ['./farmers-bar-chart.component.css']
})
export class FarmersBarChartComponent {
  componentId2 = '2';
  barchartData: {[k: string]: any} = {};
  data2: BarChartData[] = []
  // data2: BarChartData[] = [
  //   { label: "AR", value: 10 },
  //   { label: "AS", value: 20 },
  //   { label: "BR", value: 15 },
  //   { label: "CG", value: 15 },
  //   { label: "GA", value: 5 },
  //   { label: "GJ", value: 8 },
  //   { label: "HR", value: 1 },
  //   { label: "HP", value: 22 },
  //   { label: "JH", value: 18 },
  //   { label: "KA", value: 2 },
  //   { label: "KL", value: 3 },
  //   { label: "MP", value: 9 },
  //   { label: "MH", value: 9 },
  //   { label: "MN", value: 9 },
  //   { label: "ML", value: 8 },
  //   { label: "OD", value: 7 },
  //   { label: "PB", value: 6 },
  //   { label: "RJ", value: 5 },
  //   { label: "SK", value: 4 },
  //   { label: "TN", value: 3 },
  //   { label: "TS", value: 2 },
  //   { label: "TR", value: 1 },
  //   { label: "UK", value: 9 },
  //   { label: "UP", value: 9 },
  //   { label: "UK", value: 9 },
  //   { label: "WB", value: 9 },
  //   { label: "AN", value: 9 },
  //   { label: "CH", value: 9 },
  //   { label: "DD", value: 9 },
  //   { label: "DN", value: 9 },
  //   { label: "JK", value: 9 },
  //   { label: "LA", value: 9 },
  //   { label: "LD", value: 9 },
  //   { label: "PY", value: 9 },
  // ];
  constructor(private dashboardServ : DashboardService){}
ngOnInit(): void {
    this.dashboardServ.fetchBarchartDetails()
    .subscribe({
      next: response=>{
        this.barchartData = response
        this.barchartData.data.map((el: any)=>{
          const obj = {
            label: el.state_code,
            value: el.farmersCount,
            stateName: el.state_name,
            farmersPerFpo: el.farmersCount/el.fpoCount
          }
            this.data2.push(obj)
        })
      },
      error: err=>{
        console.log(err)
      }
    })
}
}
