import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject,Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }
  private searchQuerySubject = new BehaviorSubject<string>('');
  private exportSubject = new Subject<any>();

  setSearchQuery(query: string) {
    this.searchQuerySubject.next(query);
  }

  getSearchQuery(): Observable<string> {
    return this.searchQuerySubject.asObservable();
  }

  setExportClick() {
    this.exportSubject.next(void 0)
  }
  getExportClick(): Observable<any>{ 
    return this.exportSubject.asObservable();
  }
}
