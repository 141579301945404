<!-- <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sign In</title>
    <meta name="viewport" content="width=device-width" initial-scale="1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Red+Hat+Display:wght@500&display=swap" rel="stylesheet">
</head> -->
<!-- <div id="spinLoader">
  <app-loader *ngIf="isLoading"></app-loader>
</div> -->
<div class="auth-background-image"></div>
  <div class="auth-div">
    <div class="logo"><img src="../../../assets/logo.svg"></div>
  <div class="auth-main-container">
    <h1>Change Password</h1>
  <div class="error-container" *ngIf="isErrorFound">
    <span>{{errorMessage}}</span>
  </div>
    <div class="auth-form-container">
      <form #changePassword="ngForm" (ngSubmit)="onChangePassword(changePassword)">
        <div class="client-side-error-container" *ngIf="changePassword.invalid">
          <div *ngIf="changePassword.controls.newPassword.invalid && changePassword.controls.newPassword.errors?.required && (changePassword.controls.newPassword.dirty || changePassword.controls.newPassword.touched)">
           Please enter New Password.
          </div>
          <div *ngIf="changePassword.controls.newPassword.invalid && changePassword.controls.newPassword.errors?.minlength">
            Password must be at least 8 characters long.
          </div>
          <div *ngIf="changePassword.controls.newPassword.invalid && changePassword.controls.newPassword.errors?.maxlength">
            Password cannot be longer than 12 characters.
          </div>
          <div *ngIf="changePassword.controls.newPassword.invalid && changePassword.controls.newPassword.errors?.pattern">
            Password should have atleast 1 uppercase, 1 lowercase, 1 wild character and 1 number.
          </div>
          <div *ngIf="changePassword.controls.confirmNewPassword.invalid && changePassword.controls.confirmNewPassword.errors?.required && (changePassword.controls.confirmNewPassword.dirty || changePassword.controls.confirmNewPassword.touched)">
           Please confirm new Password.
          </div>
          <div *ngIf="changePassword.controls.confirmNewPassword.invalid && changePassword.controls.confirmNewPassword.errors?.minlength">
            Password must be at least 8 characters long.
          </div>
          <div *ngIf="changePassword.controls.confirmNewPassword.invalid && changePassword.controls.confirmNewPassword.errors?.maxlength">
            Password cannot be longer than 12 characters.
          </div>
          <div *ngIf="changePassword.controls.confirmNewPassword.invalid && changePassword.controls.confirmNewPassword.errors?.pattern">
            Password should have atleast 1 uppercase, 1 lowercase, 1 wild character and 1 number.
          </div>
        </div>
        <div class="input-container">
          <input class="newPassword" id="newPassword" type="password" ngModel name="newPassword" minlength="8" maxlength="12" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$" required>
            <span class="floating-label">New Password<span style="color: #FF0000;">*</span></span>
          </div>
        <div class="input-container">
          <input class="confirmNewPassword" id="confirmNewPassword" type="password" ngModel name="confirmNewPassword" minlength="8" maxlength="12" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$" required>
            <span class="floating-label">Confirm New Password<span style="color: #FF0000;">*</span></span>
          </div>
        <button id="auth-btn" name="signin" type="submit" [disabled]="changePassword.invalid"><span class="signin">Change My Password</span></button>

    </form>
    <!-- <div class="auth-container">
      <span class="account">Don't have an account<br></span>
    <a id="auth-link" routerLink="/auth/signup"><span id="auth-text">SIGN UP</span> </a>
    </div> -->
    </div>
  </div>
  </div>
<!-- </html> -->
