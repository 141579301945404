import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FpoProfileService } from '../fpo-profile.service';

interface boardOfDeirectors {
  user_id?: number
  contact_name: string;
  contact_phone: string;
  contact_email: string;
  is_charmain?: boolean; //spelled wrong bcz in DB it is spelled that.will change later
  is_key_contact?: boolean,
  is_promoter?: boolean,
  is_bod?: boolean
}

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.css']
})
export class ContactDetailsComponent implements OnInit {
  @ViewChild('contactDetails') contactDetails!: NgForm;

  completedFormSegments: boolean[] = []
  isErrorFound: boolean = false
  errorMessage: string = ''
  boardOfDirectors: boardOfDeirectors[] = [{ contact_name: '', contact_phone: '', contact_email: '' }]
  userId = this.route.snapshot.paramMap.get('userId');
  contactDetailObj : {[k: string]: any} = {};

  //inputs: any[] = [{ name: '', phone: '', email: '' }]
  constructor(private fpoProfileServ: FpoProfileService, private route: ActivatedRoute, private router: Router){}


  ngOnInit(): void {
    const userId = this.route.snapshot.paramMap.get('userId') ?? '';
    if(userId != ''){
      this.fpoProfileServ.fetchContactDetails(parseInt(userId))
      .subscribe({
        next: (responseData : any) => {
          this.contactDetailObj = responseData.data;

          if(this.contactDetailObj != undefined && this.contactDetailObj.board_of_directors.length){
            this.boardOfDirectors = [];
            this.contactDetailObj.board_of_directors.forEach((cpObj: any) => {
              this.boardOfDirectors.push({ contact_name: cpObj.contact_name, contact_phone: cpObj.contact_phone, contact_email: cpObj.contact_email });
            })
          }
        },
        error: err=> {console.log(err)
        this.errorMessage = err
        }
      })
    }
  }
  addInput() {
    this.boardOfDirectors.push({ contact_name: '', contact_phone: '', contact_email: '' });
  }
  onClickNext(contactDetails: NgForm){
    const contactDetail = contactDetails.value

    const hasNonEmptyDirectors = this.boardOfDirectors.some(director =>
      director.contact_name !== '' || director.contact_phone !== '' || director.contact_email !== ''
    );

    if(hasNonEmptyDirectors){
    this.boardOfDirectors.map(boardOfDirector => {
      if(boardOfDirector.contact_name === contactDetail.chairman){
        boardOfDirector.is_charmain = true
      }else{
        boardOfDirector.is_charmain = false
      }
      if(boardOfDirector.contact_name === contactDetail.key_person_name){
        boardOfDirector.is_key_contact = true
      }else{
        boardOfDirector.is_key_contact = false
      }
      if(boardOfDirector.contact_name === contactDetail.promoter_name){
        boardOfDirector.is_promoter = true
      }else{
        boardOfDirector.is_promoter = false
      }
      boardOfDirector.is_bod = true
      boardOfDirector.user_id = Number(this.userId)
    })
  }
  if(hasNonEmptyDirectors){
    contactDetail.board_of_directors = this.boardOfDirectors
  }else{
    contactDetail.board_of_directors = []
  }

    if(this.userId !== null){
      this.fpoProfileServ.saveContactDetails(parseInt(this.userId), contactDetail.chairman, contactDetail.chairman_phone, contactDetail.key_person_name, contactDetail.key_person_phone, contactDetail.promoter_name, contactDetail.promoter_email, contactDetail.board_of_directors)
      .subscribe({
        next: (responseData)=>{
          const segmentIndex = 1; // Replace with the appropriate segment index
  this.completedFormSegments[segmentIndex] = true;
    // Save the completion status in Local Storage with a unique key
  const localStorageKey = `completedFormSegments_${segmentIndex}`;
  localStorage.setItem(localStorageKey, JSON.stringify(this.completedFormSegments[segmentIndex]));

          this.router.navigate([`/fpo-profile/location-details/${this.userId}`])
        },
        error: err => {
          this.isErrorFound = true
          this.errorMessage = err
        }
      })

    }
  }

  onRemoveDirector(index: number){
    this.boardOfDirectors.splice(index, 1)
  }
}
