import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { NavigationBarComponent } from '../app/navigation-bar/navigation-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { FpoProfileModule } from './FPO-Profile/fpo-profile.module';
import { CountryMapChartComponent } from './dashboard/country-map-chart/country-map-chart.component';
import { DonutChartsComponent } from './dashboard/donut-charts/donut-charts.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { DashboardComponent } from './dashboard/dashboard.component';
import { FPOsBarChartComponent } from './dashboard/fpos-bar-chart/fpos-bar-chart.component';
import { FarmersBarChartComponent } from './dashboard/farmers-bar-chart/farmers-bar-chart.component';
import { BarChartComponent } from './dashboard/bar-chart/bar-chart.component';
import { FpoStatisticsComponent } from './dashboard/fpo-statistics/fpo-statistics.component';
import { AdminModule } from './admin/admin.module';

@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    CountryMapChartComponent,
    DonutChartsComponent,
    DashboardComponent,
    FPOsBarChartComponent,
    FarmersBarChartComponent,
    BarChartComponent,
    FpoStatisticsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatCardModule,
    AuthModule,
    AppRoutingModule,
    FpoProfileModule,
    AdminModule,
    BrowserAnimationsModule,
    SharedModule,
    MatIconModule,
    MatSnackBarModule
  ],
  providers: [NavigationBarComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
