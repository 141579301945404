import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl = 'https://api.bharatfpo.org/api/v1'

  constructor(private http: HttpClient) { }
  farmerGenderDeatils(){
    return this.http.get(`${this.baseUrl}/fpo/dashboard-gender-license-crop`)
  }
  fetchAnalysis(){
    return this.http.get(`${this.baseUrl}/dashboard/fetch-analysis`)
  }
  fetchBarchartDetails(){
    return this.http.get(`${this.baseUrl}/dashboard/fetch-fpos-statewise`)
  }
  fetchFpoMapData(){
    return this.http.get(`${this.baseUrl}/dashboard/fetch-fpo-mapdata`)
  }
}
