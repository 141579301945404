import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';

interface GeoJSONData {
  features: any[];
}

interface ResponseData {
  status: boolean;
  data: any[]; // You might want to specify a more specific type here
  message: string;
}

@Component({
  selector: 'app-country-map-chart',
  templateUrl: './country-map-chart.component.html',
  styleUrls: ['./country-map-chart.component.css']
})
export class CountryMapChartComponent implements OnInit {
  @ViewChild('mapContainer', { static: true }) mapContainer!: ElementRef;

  stateName: string =''
  fpoCount = 0
  farmerCount = 0
  cropsCount = 0
  revenue = 0
  fpoMapData: {[k: string]: any} = {};

  constructor(private http: HttpClient,private dashboardServ: DashboardService,private router: Router) {}


  ngOnInit(): void {
    const width = 650;
    const height = 900;

    function getCategory(state: any): string {
      const id = state.properties.id;
      if (id < 5) {
        return 'Category1';
      } else if (id>5 && id<20) {
        return 'Category2';
      } else if (id > 20 && id <25) {
        return 'Category3';
      } else {
        return 'Category4';
      }
    }

    const colorScale = d3.scaleOrdinal<string>()
    .domain(['Category1', 'Category2', 'Category3', 'Category4'])
    .range(['#58DBFB', '#DAF8FE', '#92E9FE', '#B6F1FD']);

    const projection = d3.geoMercator()
      .center([82, 22])  // Approximate center of India
      .scale(1200)
      .translate([width / 2, height / 2]);

    const geoGenerator = d3.geoPath().projection(projection);

    const svg = d3.select(this.mapContainer.nativeElement)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .style('margin', 0);


      // Add a tooltip div element
    const tooltip = d3.select('#tooltip')
                    .style('opacity', 0)

    // this.http.get<any>('../../../assets/json/new-india.geojson').subscribe((geojson) => {
    this.dashboardServ.fetchFpoMapData()
    .subscribe({
      next: (responseData : any) => {
      const states = responseData.data.features;

      svg.selectAll('path')
        .data(states)
        .enter()
        .append('path')
        .attr('d', (d: any) => geoGenerator(d) as string)
        .style('stroke', 'white')
        .style('fill', (d: any) => colorScale(getCategory(d)))
        .on('mouseover', (event, d: any) => {
          d3.select(event.currentTarget)
            .style('stroke-width', '0.17rem');
          // Show tooltip on hover
          const offsetX = -110; // Adjust this value for the desired horizontal offset
          const offsetY = -380;
          tooltip.transition()
            .duration(600)
            .style('opacity', 1)
          //  tooltip.html(`
          //  <div class="stateName"><h2>${d.properties.st_nm}</h2></div>

          //  `) //name will show on hover
            .style('left', (event.pageX + offsetX) + 'px')
            .style('top', (event.pageY + offsetY) + 'px')
            .style('border', '1px solid #ccc')
            .style('background-color', 'white')
            .style('box-shadow', '0px 4px 6px rgba(0, 0, 0, 0.1)');
            this.stateName = d.properties.st_nm
            this.fpoCount = d.properties.fpo_count;
            this.farmerCount = d.properties.farmers_count;
            this.cropsCount = d.properties.crops_count;
            this.revenue = d.properties.net_revenue;
        })
        .on('mouseout', (event, d) => {
          d3.select(event.currentTarget)
            .style('stroke-width', '');
          // Hide tooltip on mouseout
          tooltip.transition()
            .duration(500)
            .style('opacity', 0);
        })
        .on('click', (event, d :any) => {
          console.log(d.properties.st_id)
          this.router.navigate(['/admin/registered-fpo-report'],{queryParams: {state_id: d.properties.st_id}})
        });
      },
      error: err=> {
        console.log(err)
      }
    })
  }
}
