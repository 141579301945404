import { Component, ViewChild, OnInit } from '@angular/core';
import { FpoProfileService } from '../fpo-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
// import { BaseDocumentType, NumberSchemaDefinition } from 'mongoose';
interface ProductResponse {
  status: boolean;
  data: any[]; // Update this to match the actual structure of your data
  message: string;
}
 interface Product {
  id: number;
  product_name: string;
 }

@Component({
  selector: 'app-crops',
  templateUrl: './crops.component.html',
  styleUrls: ['./crops.component.css']
})
export class CropsComponent implements OnInit {
  // @ViewChild('cropDetail') cropDetail !: NgForm
  // isErrorFound: boolean = false
  // errorMessage: string = ''
  completedFormSegments: boolean[] = []
   cropItems: Product[] = []
  crops: any[] = [{ selectedProduct: null, totalRevenue: 0}]
   productId: number = 0 ;
   totalRevenue: number =0;
   userId = this.route.snapshot.paramMap.get('userId');
   cropDetailObj: {[k: string]: any} = {};
   errorMessage = ''
  constructor(private fpoProfileServ: FpoProfileService, private route: ActivatedRoute, private router: Router){}

  ngOnInit(): void {
    this.fpoProfileServ.fetchProduct()
    .subscribe({
      next: (products: ProductResponse)=>{
          //console.log(products.data)
          const productsArr = products.data
          productsArr.forEach(product=>{
           const productDataObj: Product = {
              id: product.id,
              product_name: product.product_name
            }

            this.cropItems.push(productDataObj)
          })
      }
    });

    const userId = this.route.snapshot.paramMap.get('userId');
    if(userId!==null){
      this.fpoProfileServ.fetchCropDetails(parseInt(userId))
      .subscribe({
        next: (responseData : any) => {
          this.cropDetailObj = responseData.data;
          if(this.cropDetailObj.length){
            this.crops = [];
            this.cropDetailObj.forEach((cpObj: any) => {
              this.crops.push({ selectedProduct: cpObj.product_id, totalRevenue: cpObj.total_revenue});
            })
          }
        },
        error: err=> {console.log(err)
        this.errorMessage = err
      }
      })
    }
  }
  addInput() {
    this.crops.push({ selectedProduct: null, totalRevenue: 0});
  }
  onClickNext(cropDetail: NgForm){
    // this.fpoProfileServ.saveCropDetails()
    const productDetails: any = []
    // const cropDetails = cropDetail.value
    // console.log(cropDetails)
    this.crops.forEach(crop=> {
      const cparr = []
      productDetails.push({
        user_id: this.userId,
        product_id: crop.selectedProduct ? crop.selectedProduct : null,
        total_revenue: crop.totalRevenue ? crop.totalRevenue : 0
      })

    })

    // productDetails.push(
    //   {
    //     user_id: this.userId,
    //     product_id: this.productId,
    //     total_revenue: this.totalRevenue
    //   }
    // )

    this.fpoProfileServ.saveCropDetails(Number(this.userId), productDetails)
    .subscribe({
      next: response=>{
        const segmentIndex = 4; // Replace with the appropriate segment index
        this.completedFormSegments[segmentIndex] = true;
          // Save the completion status in Local Storage with a unique key
        const localStorageKey = `completedFormSegments_${segmentIndex}`;
        localStorage.setItem(localStorageKey, JSON.stringify(this.completedFormSegments[segmentIndex]));

      },
      error: err=>{
        console.log(err)
      }
    })
  }

  onRemoveDirector(index: number){
    this.crops.splice(index, 1)
  }
}
