import { Component, ElementRef, Input, ViewChild, SimpleChanges, OnInit, AfterViewInit } from '@angular/core';
import * as d3 from 'd3';

interface BarChartData {
  label: string;
  value: number;
  stateName: string;
  farmersPerFpo: number;
}

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit, AfterViewInit {
  @ViewChild('chart', { static: true }) private chartContainer!: ElementRef;
@Input() data: BarChartData[] = []
@Input() componentId: string = '';
barchartData: {[k: string]: any} = {};
stateName: string = ''
fpoCount1 = 0
farmerCount2 = 0
farmersPerFpo = 0
  ngOnInit() {
    // this.createBarChart(this.componentId);
  }

  ngAfterViewInit(): void {
    this.createBarChart(this.componentId);
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   // Check if the componentId input property has changed
  //   if (changes.componentId) {
  //     // Access the previous and current values of componentId
  //     const previousComponentId = changes.componentId.previousValue;
  //     const currentComponentId = changes.componentId.currentValue;

  //     // Update the tooltip based on the new componentId
  //     console.log(`Component ID changed from ${previousComponentId} to ${currentComponentId}`);
  //     // You can update the tooltip or perform other actions here
  //   }
  // }

  createBarChart(componentId: any) {
    // Sample data for the bar chart
    // const data: BarChartData[] = [
    //   { label: "A", value: 10 },
    //   { label: "B", value: 20 },
    //   { label: "C", value: 15 },
    //   { label: "D", value: 25 },
    //   { label: "E", value: 4 },
    //   { label: "F", value: 12 },
    //   { label: "G", value: 70 },
    //   { label: "H", value: 55 },
    //   { label: "I", value: 30 },
    //   { label: "J", value: 30 },
    //   { label: "K", value: 30 },
    //   { label: "L", value: 30 },
    //   { label: "M", value: 30 },
    //   { label: "N", value: 30 },
    // ];

    // Set up dimensions
    // this.dasboardServ.fetchBarchartDetails()
    // .subscribe({
    //   next: response=>{
    //     this.barchartData = response
    //     this.fpoCount = this.barchartData.data.fpoCount
    //     this.farmerCount = this.barchartData.farmersCount
    //     this.farmersPerFpo = this.farmerCount/this.fpoCount
    //     console.log(this.barchartData.data)
    //     console.log(this.fpoCount)
    //     console.log(this.farmerCount)
    //     console.log(this.farmersPerFpo)
    //   },
    //   error: err=> {
    //     console.log(err)
    //   }
    // })
    const margin = { top: 20, right: 20, bottom: 40, left: 40 };
    const container = this.chartContainer.nativeElement;
const containerWidth = container.clientWidth - margin.left - margin.right; // Adjust for margins
const containerHeight = container.clientHeight - margin.top - margin.bottom; // Adjust for margins
console.log(`Container Height: ${containerHeight}`);


    // Create the SVG container
    const svg = d3.select(container)
      .append("svg")
      .attr("width", containerWidth + margin.left + margin.right)
      .attr("height", containerHeight + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create the x and y scales
    const x = d3.scaleBand()
      .domain(this.data.map(d => d.label))
      .range([0, containerWidth])
      .paddingInner(0.6)
      .paddingOuter(0.5);

    const y = d3.scaleLinear()
      .domain([0, d3.max(this.data, d => d.value) as number]) // Explicitly cast the value as a number
      .range([containerHeight, 0]);

    // Create the x and y axes
    const xAxis = d3.axisBottom(x).tickSize(0);
    const yAxis = d3.axisLeft(y).tickSize(0);

     // Add a tooltip div element
     console.log('#bar-tooltip' + componentId)
      const barTooltip = d3.select('#bar-tooltip' + componentId)
      .style('opacity', 0)

       //console.log('#bar-tooltip' + componentId)

    // Add the x axis to the chart
    const xAxisGroup = svg.append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0,${containerHeight})`)
      .call(xAxis);

    // Add the y axis to the chart
   const yAxisGroup = svg.append("g")
      .attr("class", "y axis")
      .call(yAxis);

      yAxisGroup.selectAll("text").style("fill", "#656565")
      .style("font-size","0.7rem")
      xAxisGroup.selectAll("text")
      .style("font-size","0.7rem")
      xAxisGroup.select("path").style("stroke", "#00000029");
  yAxisGroup.select("path").style("stroke", "#00000029");
    // Create the bars with slightly reduced curvy tops
    svg.selectAll(".bar")
      .data(this.data)
      .enter().append("path")
      .attr("class", "bar")
      .on('mouseover', (event, d: any) => {
        d3.select(event.currentTarget)
        // Show tooltip on hover
        let offsetX = 0
        let offsetY = 0
        if(componentId === '1'){
           offsetX = -100; // Adjust this value for the desired horizontal offset
           offsetY = -1520;

        }else{
           offsetX = -100; // Adjust this value for the desired horizontal offset
           offsetY = -2150;
        }
         barTooltip.transition()
           .duration(600)
           .style('opacity', 1)
           .style('left', (event.pageX + offsetX) + 'px')
           .style('top', (event.pageY + offsetY) + 'px')
           .style('border', '1px solid #ccc')
       .style('background-color', 'white')
       .style('box-shadow', '0px 4px 6px rgba(0, 0, 0, 0.1)');
       console.log(d)
        // Update tooltip data based on componentId
        this.stateName = (componentId === '1' || componentId === '2')? d.stateName: ''
    this.fpoCount1 = (componentId === '1') ? d.value : null;
    this.farmerCount2 = (componentId === '2') ? d.value : null;
    this.farmersPerFpo = (componentId === '1' || componentId === '2')? d.farmersPerFpo: 'ok';
    console.log(this.fpoCount1)
    console.log(this.farmerCount2)
    console.log(this.farmersPerFpo)
      })
      .on('mouseout', (event, d) => {
        d3.select(event.currentTarget)
        // Hide barTooltip on mouseout
         barTooltip.transition()
           .duration(500)
           .style('opacity', 0);
      })
      .attr("d", d => {
        const barHeight = containerHeight - y(d.value);
        const barWidth = x.bandwidth();
        const xStart = x(d.label) || 0;
        const yStart = y(d.value);
        const curveOffset = 3; // Adjust this value to control the curvature

        // Draw a curved top using a quadratic Bezier curve
        const path = `M ${xStart} ${yStart}
                      Q ${xStart + barWidth / 2} ${yStart - curveOffset}, ${xStart + barWidth} ${yStart}
                      V ${containerHeight}
                      H ${xStart}
                      Z`;


        return path;
      })
      .attr("fill", "#48DBFB")

  ;


  }
}
