<div class="main-container">
  <!-- <div class="Fpo-table-heading"><h1>Registered Members</h1></div> -->
  <div class="table-container" #TABLE>
    <table mat-table [dataSource]="dataSource" #table class="mat-elevation-z8">
      <ng-container matColumnDef="FPO Name">
        <th mat-header-cell *matHeaderCellDef class="header-title"> FPO Name </th>
        <td *matCellDef="let element">
          <a class="table-items" routerLink="/fpo-profile/profile-summary/{{element.userId}}" target="_blank">
           {{element.fpoName}}
          </a>
          </td>
      </ng-container>
      <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef class="header-title"> Email </th>
        <td *matCellDef="let element" class="table-items"> {{element.email}} </td>
      </ng-container>
      <ng-container matColumnDef="Phone No">
        <th mat-header-cell *matHeaderCellDef class="header-title"> Phone No </th>
        <td *matCellDef="let element" class="table-items"> {{element.mobileNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="District">
        <th mat-header-cell *matHeaderCellDef class="header-title"> District </th>
        <td *matCellDef="let element" class="table-items"> {{element.district}} </td>
      </ng-container>
      <ng-container matColumnDef="State">
        <th mat-header-cell class="header-title" *matHeaderCellDef> State </th>
        <td *matCellDef="let element" class="table-items"> {{element.state}} </td>
      </ng-container>
      <ng-container matColumnDef="Entry Year">
        <th mat-header-cell class="header-title" *matHeaderCellDef> Entry Year </th>
        <td *matCellDef="let element" class="table-items"> {{element.entryYear}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </div>
</div>
