import { Component } from '@angular/core';

@Component({
  selector: 'app-email-verification-success',
  templateUrl: './email-verification-success.component.html',
  styleUrls: ['./email-verification-success.component.css']
})
export class EmailVerificationSuccessComponent {

}
