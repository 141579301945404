import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';

interface BarChartData {
  label: string;
  value: number;
  stateName: string;
  farmersPerFpo: number;
}

interface ResponseData {
  status: boolean;
  data: any[]; // You might want to specify a more specific type here
  message: string;
}

@Component({
  selector: 'app-fpos-bar-chart',
  templateUrl: './fpos-bar-chart.component.html',
  styleUrls: ['./fpos-bar-chart.component.css']
})
export class FPOsBarChartComponent implements OnInit {
  componentId1 = '1';
  barchartData: {[k: string]: any} = {};
  data1: BarChartData[] = []
  //  data1: BarChartData[] = [
  //    { label: "AR", value: 30 },
  //    { label: "AS", value: 28 },
  //    { label: "BR", value: 25 },
  //    { label: "CG", value: 24 },
  //    { label: "GA", value: 23 },
  //    { label: "GJ", value: 21 },
  //    { label: "HR", value: 20 },
  //    { label: "HP", value: 19 },
  //    { label: "JH", value: 18 },
  //    { label: "KA", value: 17 },
  //    { label: "KL", value: 15 },
  //    { label: "MP", value: 13 },
  //    { label: "MH", value: 12 },
  //    { label: "MN", value: 10 },
  //    { label: "ML", value: 9 },
  //    { label: "OD", value: 8 },
  //    { label: "PB", value: 7 },
  //    { label: "RJ", value: 5 },
  //    { label: "SK", value: 4 },
  //    { label: "TN", value: 3 },
  //    { label: "TS", value: 2 },
  //    { label: "TR", value: 1 },
  //    { label: "UK", value: 9 },
  //    { label: "UP", value: 9 },
  //    { label: "UK", value: 5 },
  //    { label: "WB", value: 3 },
  //    { label: "AN", value: 9 },
  //    { label: "CH", value: 9 },
  //    { label: "DD", value: 9 },
  //    { label: "DN", value: 9 },
  //    { label: "JK", value: 9 },
  //    { label: "LA", value: 9 },
  //    { label: "LD", value: 9 },
  //    { label: "PY", value: 9 },
  //     ];
constructor(private dashboardServ : DashboardService){}
ngOnInit(): void {
    this.dashboardServ.fetchBarchartDetails()
    .subscribe({
      next: response=>{
        this.barchartData = response

        this.barchartData.data.map((el: any)=>{
            this.data1.push({label: el.state_code, value: el.fpoCount, stateName: el.state_name, farmersPerFpo: el.farmersCount/el.fpoCount})
        })
      },
      error: err=>{
        console.log(err)
      }
    })
}
}
