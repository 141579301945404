import { Component } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.css']
})
export class VerifyAccountComponent {
  isLoading: boolean = false
 constructor(private authServ: AuthService){}
// this.authServ.signUp(
//   adminDetail.FPOName,
//   adminDetail.mobileNumber,
//       adminDetail.email,
//       adminDetail.password,
//       adminDetail.confirmPassword
// ).subscribe(responseData=>{
//   console.log(responseData)
//   this.isSignedUp=true
// })
onResendVerificationLink(){
  const userToken = sessionStorage.getItem('userToken')
  this.isLoading = true
  if(userToken!==null){
  this.authServ.resendVerificatinLink(userToken).subscribe({
    next: resData=>{
      console.log(resData)
      this.isLoading = false
    },
    error: err=>{
      console.log(err)
      this.isLoading = false
    }
  })
  }
}
}
