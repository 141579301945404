import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from '../auth.service';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent {
  //isLoading: boolean = false
  // @ViewChild('forgetPassword') forgetPassword!: NgForm
  isErrorFound: boolean = false
  errorMessage = ''
  constructor(private authServ: AuthService, private router: Router, private snackBar: MatSnackBar){}

  onSendLink(forgetPassword: NgForm){
    //this.isLoading = true
    const formData = forgetPassword.value
    this.authServ.requestPasswordReset(formData.email)
    .subscribe({
      next: (res)=>{
        this.router.navigate(['/auth/login'])
        const snackBarConfig: MatSnackBarConfig = {
           duration: 4000,
          verticalPosition: 'top',
          // panelClass: ['custom-snackbar'],
        };
        // Show a notification toast
    this.snackBar.open('Reset Password email sent!', 'Close', snackBarConfig);

      },
      error: (err)=>{
        console.log(err.error.message)
        this.isErrorFound = true
        this.errorMessage = err.error.message
      }
    })
  }
}
