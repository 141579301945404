import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs';

interface ProductResponse {
  status: boolean;
  data: any[]; // Update this to match the actual structure of your data
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class FpoProfileService {
   baseUrl = 'https://api.bharatfpo.org/api/v1'

  constructor(private http: HttpClient) { }

  private isProfileSummaryPageSubject = new BehaviorSubject<boolean>(false);
  isProfileSummaryPage$ = this.isProfileSummaryPageSubject.asObservable();

  setIsProfileSummaryPage(value: boolean) {
    this.isProfileSummaryPageSubject.next(value);
  }

  saveFpoDetails(
    userId: number,
    cinRegNo: string,
    fssaiNo: string,
    gstNo:  string,
    riCbNo:  string,
    totalLand: number,
    villageCount:  number,
    totalSharehoder:  number,
    womenStakholders:  number,
    fpoDetailId: number){
    const fpoDetailsObj = {
      cinRegNo,
      fssaiNo,
      gstNo ,
      riCbNo ,
      totalLand,
      villageCount,
      totalSharehoder,
      womenStakholders,
      fpoDetailId
    }
    return this.http.post(`${this.baseUrl}/fpo/save-fpo-detail/${userId}`, fpoDetailsObj)
  }


  fetchFpoDetails(userId: number){
    return this.http.get(`${this.baseUrl}/fpo/fetch-fpo-detail/${userId}`)
  }

  saveContactDetails(userId: number, chairman: string, chairman_phone: number, key_person_name: string, key_person_phone: number, promoter_name: string, promoter_email: string, board_of_directors: object){
    const contactDetailObj = {
      chairman,
       chairman_phone,
        key_person_name,
        key_person_phone,
        promoter_name,
        promoter_email,
        board_of_directors
    }
    return this.http.post(`${this.baseUrl}/fpo/save-contact-details/${userId}`, contactDetailObj)
  }

  fetchContactDetails(userId: number){
    return this.http.get(`${this.baseUrl}/fpo/fetch-contact-details/${userId}`)
  }

  saveLocationDetails(userId: number, pincode: number, district: string, state: string, block: string, mandi: string, address: string, location: string, fpoLocationId: number ){
    const locationDetailsObj ={
      userId,
      pincode,
      district,
      state,
      block,
      mandi,
      address,
      location,
      fpoLocationId
    }

   return this.http.post(`${this.baseUrl}/fpo/save-fpo-location/${userId}`, locationDetailsObj)
  }

  fetchLocation(userId: number){
    return this.http.get(`${this.baseUrl}/fpo/fetch-fpo-location/${userId}`)
  }

  saveCropDetails(userId: number, productDetails: object){
    const productDetailsObj = {
      productDetails
    }

    return this.http.post(`${this.baseUrl}/fpo/save-crop-details/${userId}`, productDetailsObj)
  }

  fetchCropDetails(userId: number){
    return this.http.get(`${this.baseUrl}/fpo/fetch-crop-details/${userId}`)
  }

  fetchProduct(){
    return this.http.get<ProductResponse>(`${this.baseUrl}/fpo/products`)
  }

  fetchStates(){
    return this.http.get(`${this.baseUrl}/fpo/fetch-states`)
  }

  saveFinanceDetails(userId: number, financeYear: number, netTurnover: number, licenseArr: object, annualIncomePerMember: number, revenuePerAcre: number, financeDetailId : number){
    const financeDetailObj = {
      financeYear,
    netTurnover,
    licenseArr,
    annualIncomePerMember,
    revenuePerAcre,
    financeDetailId
    }
    return this.http.post(`${this.baseUrl}/fpo/save-finance-details/${userId}`, financeDetailObj)
  }

  fetchFinance(userId: number){
    return this.http.get<ProductResponse>(`${this.baseUrl}/fpo/fetch-finance-details/${userId}`)
  }

  fetchProfileCompletion(userId: number){
    return this.http.get(`${this.baseUrl}/fpo/profile-completion/${userId}`)
  }

  fetchProfilesummary(userId: number){
    return this.http.get(`${this.baseUrl}/fpo/fetch-profile/${userId}`)
  }
}


