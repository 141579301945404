<!-- <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sign In</title>
    <meta name="viewport" content="width=device-width" initial-scale="1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Red+Hat+Display:wght@500&display=swap" rel="stylesheet">
</head> -->

<div class="auth-background-image"></div>
  <div class="auth-div">
    <div class="logo"><img src="../../../assets/logo.svg"></div>

  <div class="auth-main-container">
    <h1>Forget Password?</h1>

  <!-- <div class="content"><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></div> -->
  <div class="error-container" *ngIf="isErrorFound">
    <span>{{errorMessage}}</span>
  </div>
    <div class="auth-form-container">
      <form #forgetPassword="ngForm" (ngSubmit)="onSendLink(forgetPassword)">
        <div class="client-side-error-container" *ngIf="forgetPassword.invalid">
          <div *ngIf="forgetPassword.controls.email.invalid && forgetPassword.controls.email.errors?.required && (forgetPassword.controls.email.dirty || forgetPassword.controls.email.touched)">
            Email/ID is required.
          </div>
          <div *ngIf="forgetPassword.controls.email.invalid && forgetPassword.controls.email.errors?.pattern">
            Please enter valid Email Id.
          </div>

        </div>
        <div class="input-container">
          <input class="email" id="email" type="email" ngModel name="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$" required>
            <span class="floating-label">Email/ID<span style="color: #FF0000;">*</span></span>
          </div>
        <button id="auth-btn" name="signin" type="submit" [disabled]="forgetPassword.invalid">Send Link</button>

    </form>
    </div>
  </div>
  </div>
<!-- </html> -->
