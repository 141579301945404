import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router, NavigationEnd } from '@angular/router';
import { FpoProfileService } from '../fpo-profile.service';
import { BrowserModule } from '@angular/platform-browser';

import { filter } from 'rxjs';

@Component({
  selector: 'app-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.css']
})
export class ProfileSummaryComponent implements OnInit{

  errorMessage: string = ''
  fpoUser: {[k: string]: any} = {}
  fpoDetails : {[k: string]: any} = {}
  fpoContactDetails : {[k: string]: any} = {}
  fpoLocationDetails : {[k: string]: any} = {}
  fpoFinanceDetails : {[k: string]: any} = {}
  fpoCropDetails : any[] = []

  constructor(private fpoProfileServ: FpoProfileService, private route: ActivatedRoute, private router: Router){}
  userId = this.route.snapshot.paramMap.get('userId');
  lsUserId  = window.localStorage.getItem('userId');
  isAdmin = window.localStorage.getItem('isAdmin');
  ngOnInit(): void {

    if(this.userId!==null){
      if(!this.isAdmin && this.lsUserId !== null && this.lsUserId !== this.userId){
        this.router.navigate(['/fpo-profile/profile-summary/',+this.lsUserId])
      }
      this.fpoProfileServ.fetchProfilesummary(parseInt(this.userId)
      ).subscribe({
        next: (responseData : any) => {
          this.fpoUser = responseData.data.fpoUser ?? null;
          this.fpoDetails = responseData.data.fpoDetails ?? null;
          this.fpoContactDetails = responseData.data.fpoContactDetails ?? null;
          this.fpoLocationDetails = responseData.data.fpoLocationDetails ?? null;
          this.fpoFinanceDetails = responseData.data.fpoFinanceDetails ?? null;
          this.fpoCropDetails = responseData.data.fpoCropDetails ?? null;
          console.log(this.fpoLocationDetails)

        },
        error: err=> {console.log(err)
        this.errorMessage = err
      }
      })
    }

  }

}
