import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CountryMapChartComponent } from './dashboard/country-map-chart/country-map-chart.component';
import { DonutChartsComponent } from './dashboard/donut-charts/donut-charts.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FPOsBarChartComponent } from './dashboard/fpos-bar-chart/fpos-bar-chart.component';
import { BarChartComponent } from './dashboard/bar-chart/bar-chart.component';
import { FarmersBarChartComponent } from './dashboard/farmers-bar-chart/farmers-bar-chart.component';

const routes: Routes = [
  {path:'',redirectTo: 'auth/login', pathMatch: 'full'},
  {path: 'map-chart', component: CountryMapChartComponent},
  {path: 'donut-chart', component: DonutChartsComponent},
  {path: 'fpos-bar-chart', component: FPOsBarChartComponent},
  {path: 'farmers-bar-chart', component: FarmersBarChartComponent},
  {path: 'bar-chart', component: BarChartComponent},
  {path: 'dashboard', component: DashboardComponent},
  // {path: 'country-map', component: CountryMapChartComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
