<div class="map-container" #mapContainer><h2>FPOs by Numbers</h2></div>
<div id="tooltip">
  <div class="state-name"><h2>{{stateName}}</h2></div>
  <div class="number-of-FPO"><h2>{{fpoCount}}</h2></div>
  <div class="tooltip-sub-info">
  <div class="farmers"><h2>Farmers</h2><h2>{{farmerCount}}</h2></div><hr>
  <div class="crops"><h2>Crops</h2><h2>{{cropsCount}}</h2></div><hr>
  <div class="revenue"><h2>Revenue</h2><h2>{{revenue}}</h2></div>
  </div>
</div>
