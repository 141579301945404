<div class="profile-main-container">
  <div class="list-container">
    <div class="detail-title-container">
      <div class="detail-title"><h1>FPO Details</h1></div>
      <ng-template [ngIf]="!isAdmin">
        <div class="edit-icon"><a routerLink="/fpo-profile/fpo-details/{{userId}}"><i class="fa-solid fa-pen-to-square"></i></a></div>
      </ng-template>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>FPO Name</label></div>
      <div class="data-container"><span>{{ fpoUser.FPO_name }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>CIN/R No.</label></div>
      <div class="data-container"><span>{{ fpoDetails.cin_reg_no }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>FSSAI No.</label></div>
      <div class="data-container"><span>{{ fpoDetails.fssai_no }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>GST No.</label></div>
      <div class="data-container"><span>{{ fpoDetails.gst_no }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>RI/CB/NO</label></div>
      <div class="data-container"><span>{{ fpoDetails.ri_cb_no }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Total land under FPO (in acres)</label></div>
      <div class="data-container"><span>{{ fpoDetails.total_land }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Villages</label></div>
      <div class="data-container"><span>{{ fpoDetails.village_count }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Total Shareholders</label></div>
      <div class="data-container"><span>{{ fpoDetails.total_sharehoder }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Number of women stakeholder</label></div>
      <div class="data-container"><span>{{ fpoDetails.women_stakholders }}</span></div>
    </div>
    <div class="detail-title-container">
      <div class="detail-title"><h1>Contact Details</h1></div>
      <ng-template [ngIf]="!isAdmin">
        <div class="edit-icon"><a routerLink="/fpo-profile/contact-details/{{userId}}"><i class="fa-solid fa-pen-to-square"></i></a></div>
      </ng-template>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Chairman</label></div>
      <div class="data-container"><span>{{ fpoContactDetails.chairman }}({{ fpoContactDetails.chairman_phone }})</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Key Contact</label></div>
      <div class="data-container"><span>{{ fpoContactDetails.key_person_name }}({{ fpoContactDetails.key_person_phone }})</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Promoted By</label></div>
      <div class="data-container"><span>{{ fpoContactDetails.promoter_name }}({{ fpoContactDetails.promoter_email }})</span></div>
    </div>
    <ng-template [ngIf]="fpoContactDetails.board_of_directors">
      <div class="detail-title-container">
        <div class="detail-title"><h1>Board of Directors</h1></div>
      </div>
      <div *ngFor='let bod of fpoContactDetails.board_of_directors;' class="detail-info">
        <div class="label-container"><label>{{ bod.contact_name }}</label></div>
        <div class="label-container"><span>{{ bod.contact_phone }}</span></div>
        <div class="data-container"><span>{{ bod.contact_email }}</span></div>
      </div>
    </ng-template>
    <div class="detail-title-container">
      <div class="detail-title"><h1>Location</h1></div>
      <ng-template [ngIf]="!isAdmin">
        <div class="edit-icon"><a routerLink="/fpo-profile/location-details/{{userId}}"><i class="fa-solid fa-pen-to-square"></i></a></div>
      </ng-template>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Address</label></div>
      <div class="data-container"><span>{{fpoLocationDetails.address}}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Pincode</label></div>
      <div class="data-container"><span>{{fpoLocationDetails.pincode}}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>District</label></div>
      <div class="data-container"><span>{{fpoLocationDetails.district}}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>State</label></div>
      <div class="data-container"><span>{{fpoLocationDetails.state}}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Block</label></div>
      <div class="data-container"><span>{{fpoLocationDetails.block}}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Mandi</label></div>
      <div class="data-container"><span>{{fpoLocationDetails.mandi}}</span></div>
    </div>
    <!-- <div class="detail-info">
      <div class="label-container"><label>MAP</label></div>
    </div> -->
    <div class="detail-title-container">
      <div class="detail-title"><h1>Financial Details</h1></div>
      <ng-template [ngIf]="!isAdmin">
        <div class="edit-icon"><a routerLink="/fpo-profile/financial-details/{{userId}}"><i class="fa-solid fa-pen-to-square"></i></a></div>
      </ng-template>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Turnover FY {{ fpoFinanceDetails.financeYear }}</label></div>
      <div class="data-container"><span>₹ {{ fpoFinanceDetails.netTurnover }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Annual Agri Income Per Member</label></div>
      <div class="data-container"><span>₹ {{ fpoFinanceDetails.annualIncomePerMember }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Agri Revenue Per Acre</label></div>
      <div class="data-container"><span>₹ {{ fpoFinanceDetails.revenuePerAcre }}</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Licenses</label></div>
      <div class="data-container"><span>{{ fpoFinanceDetails.licenseArr?.toString() }}</span></div>
    </div>
    <!-- <div class="detail-title-container">
      <div class="detail-title"><h1>Licences</h1></div>
      <div class="edit-icon"><span><i class="fa-solid fa-pen-to-square"></i></span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Seeds</label></div>
      <div class="data-container"><span>Yes</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Fertilizer</label></div>
      <div class="data-container"><span>Yes</span></div>
    </div>
    <div class="detail-info">
      <div class="label-container"><label>Pesticide</label></div>
      <div class="data-container"><span>No</span></div>
    </div> -->
    <ng-template [ngIf]="fpoCropDetails">
      <div class="detail-title-container">
        <div class="detail-title"><h1>Crops/Produce</h1></div>
        <ng-template [ngIf]="!isAdmin">
          <div class="edit-icon"><a routerLink="/fpo-profile/crops-produce/{{userId}}"><i class="fa-solid fa-pen-to-square"></i></a></div>
        </ng-template>
      </div>
      <div class="detail-info">
        <div class="label-container"><label>Crop</label></div>
        <div class="data-container"><span>Total Revenue</span></div>
      </div>
      <div *ngFor="let fpCrp of fpoCropDetails;" class="detail-info">
        <div class="label-container"><label>{{ fpCrp.product.product_name }}</label></div>
        <div class="data-container"><span>{{ fpCrp.total_revenue }}</span></div>
      </div>
    </ng-template>
  </div>
</div>
