<div class="fpo-profile-container">
  <!-- <div class="error-container" *ngIf="isErrorFound">{{errorMessage}}</div> -->
  <form #cropDetail="ngForm" (ngSubmit)="onClickNext(cropDetail)">
    <div class="form-part2">
      <div class="form-subpart" *ngFor="let crop of crops; let i = index">
        <div class="input-container">
          <select [(ngModel)]="crop.selectedProduct" name="{{ 'Crop/Produce-' + i }}" required>
            <option value="" disabled>Crop/Produce</option>
            <option *ngFor="let item of cropItems" [ngValue]="item.id">{{ item.product_name }}</option>
          </select>
          <span class="floating-label">Crop/Produce<span style="color: #FF0000;">*</span></span>
        </div>

        <div class="input-container">
          <input type="text" [(ngModel)]="crop.totalRevenue" name="{{ 'percentageTotalRevenue-' + i }}" required>
          <span class="floating-label">Total Revenue<span style="color: #FF0000;">*</span></span>
        </div>
        <div class="delete-btn" (click) = "onRemoveDirector(i)"><span><i class="fa-solid fa-trash-can" style="color: #60AA3F; padding-left: 5px; padding-top: 4px;"></i></span></div>
      </div>
      <div class="add-btn" (click)="addInput()"><span><i class="fa-solid fa-plus" style="color: #000000; font-size: 1rem; font-weight: 100; padding-left: 5px; padding-top: 4px;"></i></span></div>
    </div>
    <div><button type="submit" name="next" id="next-btn">NEXT</button></div>
  </form>
  <div class="background-container">
    <div class="background-image" [style.backgroundImage]="'url(../../../../../assets/financial-details-bg-img.svg)'"></div>
  </div>
  </div>
