import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FpoDetailsComponent } from '../FPO-Profile/fpo-details/fpo-details.component';
import { ContactDetailsComponent } from '../FPO-Profile/contact-details/contact-details.component';
import { LocationDetailsComponent } from '../FPO-Profile/location-details/location-details.component';
import { FpoProfileNavbarComponent } from '../shared/fpo-profile-navbar/fpo-profile-navbar.component';
import { FinancialDetailsComponent } from '../FPO-Profile/financial-details/financial-details.component';
import { CropsComponent } from '../FPO-Profile/crops/crops.component';
import { FpoProfileComponent } from './fpo-profile.component';
import { ProfileSummaryComponent } from './profile-summary/profile-summary.component';

const routes: Routes = [
  {path: 'fpo-profile', component: FpoProfileComponent,  children: [
    {path:'', redirectTo: 'fpo-details/:userId', pathMatch: 'full'},
    {path:'fpo-details/:userId',component:FpoDetailsComponent},
    {path:'contact-details/:userId',component:ContactDetailsComponent},
    {path:'location-details/:userId',component:LocationDetailsComponent},
    {path:'financial-details/:userId',component: FinancialDetailsComponent},
    {path:'crops-produce/:userId',component: CropsComponent},
    {path:'navbar',component: FpoProfileNavbarComponent},
    {path:'profile-summary/:userId',component: ProfileSummaryComponent}
  ]},
  //{path: 'fpo-profile', component: FpoProfileComponent}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class FpoRoutingModule { }
