import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import {  Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';

// interface DataPoint {
//   category: string;
//   value: number;
//   startAngle: number;
//   endAngle: number;
// }

export interface Subcategory {
  label: string;
  value: number;
}

export interface GenderObject {
  label: string;
  value: number;
}
@Component({
  selector: 'app-donut-charts',
  templateUrl: './donut-charts.component.html',
  styleUrls: ['./donut-charts.component.css']
})
export class DonutChartsComponent implements OnInit {
  @ViewChild('donutChartContainer', { static: true }) donutChartContainer!: ElementRef;
  @ViewChild('cropsDonutChartContainer', { static: true }) cropsDonutChartContainer!: ElementRef;
  @ViewChild('licenseChartContainer', { static: true }) licenseChartContainer!: ElementRef;

  fpoDetailObj: {[k: string]: any} = {};
   dashboradDonutChartFarmersGenderData = []
   dashboradDonutChartLicenseData = []
   dashboradDonutChartCropsData = []
   genderArr: GenderObject[] = []
   FPOCount = 0
   maleCount = 0
   femaleCount = 0
    totalFarmers = 0

    cropDetailArray: any = []
    sumOfRevenueOfAllProducts = 0

  constructor(private dashboardServ : DashboardService, private router: Router){}
  ngOnInit() {

    this.dashboardServ.farmerGenderDeatils()
    .subscribe({
      next: resData =>{
        this.fpoDetailObj = resData
         this.dashboradDonutChartFarmersGenderData = this.fpoDetailObj.data.farmersGender
         this.FPOCount = this.dashboradDonutChartFarmersGenderData.length
         this.dashboradDonutChartLicenseData = this.fpoDetailObj.data.licenses
         this.dashboradDonutChartCropsData = this.fpoDetailObj.data.crops
         
         this.createGenderDonutChart();
    this.createLicenseChart();
    this.createCropsDonutChart();
      },
      error: err=>{
        console.log(err)
      }
    })
  }

  createGenderDonutChart() {
        this.dashboradDonutChartFarmersGenderData.forEach((data: any)=>{
           this.maleCount += data.total_sharehoder - data.women_stakholders
          this.femaleCount += data.women_stakholders
        })
        this.totalFarmers = this.femaleCount + this.maleCount
        const genderMaleObj: GenderObject = {
          label: "Men",
          value: this.maleCount
        }
        const genderFemaleObj: GenderObject = {
          label: "Women",
          value: this.femaleCount
        }
        this.genderArr.push(genderMaleObj, genderFemaleObj)
        this.renderGenderChart()

  }
    // Set up dimensions
    renderGenderChart(){
    const width = 398; //420
    const height = 232; //250
    const radius = Math.min(width, height) / 2;

    // Create color scale
    const color = d3.scaleOrdinal<string>()
      .domain(this.genderArr.map(d => d.label))
      .range(["#F9CA24", "#48DBFB"]);

    // Create arc for the donut chart
    const arc = d3.arc<d3.PieArcDatum<{ label: string; value: number; }>>()
      .innerRadius(radius * 0.85)
      .outerRadius(radius);

    // Create pie layout
    const pie = d3.pie<{ label: string; value: number; }>()
      .value((d) => d.value);

    // Create SVG element
    const svg = d3.select(this.donutChartContainer.nativeElement)
      .append("svg")
      .attr("width", width)
      .attr("height", height + 20)
      .append("g")
      .attr("transform", `translate(${radius + 10}, ${height / 2 + 10})`)
      .style("margin-left", 12);
// Create arc for the hovered donut slice
const arcHovered = d3.arc<d3.PieArcDatum<{ label: string; value: number; }>>()
  .innerRadius(radius * 0.8) // Adjust the inner radius on hover
  .outerRadius(radius * 1.05); // Adjust the outer radius on hover
  const dataText = svg.append("text")
  .attr("class", "donut-data-text")
    .attr("text-anchor", "middle")
    .attr("dy", "0.18em")
    .style("font-size", 27)
    .style("font-weight", "bold")
    .style("fill", "#333")
    .text(this.totalFarmers);

    // Generate donut slices
    const totalFarmersValue = this.totalFarmers
    const slices = svg.selectAll("path")
      .data(pie(this.genderArr))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.label))
      .on("mouseover", function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("d", arcHovered as any);
        const value = d.data.value
          dataText.text(`${value}`)
    .attr("dy", "0.18em")
    .style("fill", color(d.data.label)); // Adjust the vertical position of the first line

      })

      .on("mouseout", function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("d", arc as any);

          dataText.text(totalFarmersValue)
          .style("font-size", 27)
          .style("font-weight", "bold")
          .style("fill", "");
      });

    // Add legend
    const legendGroup = svg.append("g")
      .attr("transform", `translate(${radius + 40}, ${-radius + 20})`);

    const legend = legendGroup.selectAll(".legend")
      .data(this.genderArr)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) => `translate(0, ${i * 30})`);

    legend.append("circle")
      .attr("cx", 8)
      .attr("cy", 70)
      .attr("r", 6)
      .style("fill", (d) => color(d.label));

    legend.append("text")
      .attr("x", 30)
      .attr("y", 70)
      .attr("dy", "0.35em")
      .style("fill", (d) => color(d.label))
      .style("font-size", 14)
      .text((d) => `${d.label} - ${d3.format(".1%")(d.value / d3.sum(this.genderArr, (d) => d.value))}`);

    // Optionally, you can add tooltips and other interactive features.
  }

  createCropsDonutChart() {
    // Sample data
    // const data = [
    //   { label: "Wheat", value: 40 },
    //   { label: "Maize", value: 10 },
    //   { label: "Cotton", value: 20 },
    //   { label: "Sugarcane", value: 20 },
    //   { label: "Millets", value: 20 },
    //   { label: "Pulses", value: 20 },
    //   { label: "Rice", value: 20 },
    //   { label: "Others", value: 20 },
    // ];
    

    this.dashboradDonutChartCropsData.forEach((cropDetail: any)=>{
     
      const cropDetailObj = {
        label: cropDetail.product.product_name,
        value: cropDetail.total_revenue
      }
      this.cropDetailArray.push(cropDetailObj)
       this.sumOfRevenueOfAllProducts += cropDetail.total_revenue
      
    })
    const totalRevenueOfAllProducts = this.sumOfRevenueOfAllProducts.toFixed(2);
    // Set up dimensions
    const width = 600;
    const height = 242;
    const radius = Math.min(width, height) / 2;

    // Create color scale
    const color = d3.scaleOrdinal<string>()
      .domain(this.cropDetailArray.map((d: any) => d.label))
      .range(["#EE5A24", "#833471", "#009432", "#D980FA", "#FF7675", "#48DBFB", "#F9CA24", "#335CB2"]);

    // Create arc for the donut chart
    const arc = d3.arc<d3.PieArcDatum<{ label: string; value: number; }>>()
      .innerRadius(radius * 0.85)
      .outerRadius(radius);

    // Create pie layout
    const pie = d3.pie<{ label: string; value: number; }>()
      .value((d) => d.value);

    // Create SVG element
    const svg = d3.select(this.cropsDonutChartContainer.nativeElement)
      .append("svg")
      .attr("width", width)
      .attr("height", height +20)
      .append("g")
      .attr("transform", `translate(${width/4}, ${height / 2 + 10})`);

      const arcHovered = d3.arc<d3.PieArcDatum<{ label: string; value: number; }>>()
  .innerRadius(radius * 0.8) // Adjust the inner radius on hover
  .outerRadius(radius * 1.05); // Adjust the outer radius on hover

  // Create a text element for displaying data inside the hollow part
  const dataText = svg.append("text")
  .attr("class", "donut-data-text")
    .attr("text-anchor", "middle")
    .attr("dy", "0.18em")
    .style("font-size", 27)
    .style("font-weight", "bold")
    .style("fill", "#333")
    .text(totalRevenueOfAllProducts);

    // Add a <tspan> element for the second line of text (value with unit)
  dataText.append("tspan")
  .attr("x", 0)
  .attr("dy", "1.1em") // Adjust the vertical position of the second line
  .text("Rs.")
  .style("font-size", 20)
  .style("font-weight", "500");
  const initialDataTextContent = dataText.text();
    // Generate donut slices
    const slices = svg.selectAll("path")
      .data(pie(this.cropDetailArray))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.label))
      .on("mouseover", function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("d", arcHovered as any);
          // Display data inside the hollow part on mouseover
      // const percentage = d.value / d3.sum(data, (d) => d.value);
      // const percentageText = d3.format(".1%")(percentage);
      // dataText.text(`${d.data.label} - ${percentageText}`);

      // Display data inside the hollow part on mouseover
  // Display data inside the hollow part on mouseover
  //const value = d.value.toLocaleString(); // Format value with commas
  const value = d.data.value // Format value with commas
  const unit = "Rs."; // Unit text
  // const additionalInfo = `Additional Information: ${d.additionalInfo}`;
  const additionalInfo = `297 Hectares`;

  // Set the content for the text element with separate lines
  //dataText.text(`${d.data.label}\n${value}\n${unit}`)
  dataText.text(`${value}`)
    .attr("dy", "0.18em")
    .style("fill", color(d.data.label)); // Adjust the vertical position of the first line
        dataText.append('tspan')
        .text(`${unit}`)
        .attr("x", 0)
        .attr("dy", "1.2em")
        .style("font-size", 20)
  .style("font-weight", "500")
  .style("fill",color(d.data.label));
  // Add a <title> element for the tooltip
  // dataText.append("title")
  //   .text(`{additionalInfo}`);
      })
      .on("mouseout", function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("d", arc as any);

          dataText.text(totalRevenueOfAllProducts)
          .style("font-size", 27)
          .style("font-weight", "bold")
          .style("fill", "");

          dataText.append("tspan")
          .text("Rs.")
          .attr("x", 0)
          .attr("dy", "1.2em")
          .style("font-size", 20)
          .style("font-weight", "500")

      });


    // Add legend
    const legendGroup = svg.append("g")
      .attr("transform", `translate(${radius + 40}, ${-radius + 20})`);

    const legend = legendGroup.selectAll(".legend")
      .data(this.cropDetailArray)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) => `translate(0, ${i * 30})`);

    legend.append("circle")
      .attr("cx", 8)
      .attr("cy", 5)
      .attr("r", 6)
      .style("fill", (d: any) => color(d.label));

    legend.append("text")
      .attr("x", 24)
      .attr("y", 5)
      .attr("dy", "0.35em")
      .style("font-size", 14)
      .style("font-family", "Red Hat Display")
      .style("fill", (d: any) => color(d.label))
      .text((d: any) => `${d.label} - ${d3.format(".1%")(d.value / d3.sum(this.cropDetailArray, (d: any) => d.value))}`);

    // Optionally, you can add tooltips and other interactive features.
  }
  // private data: DataPoint[] = [
  //   { category: 'Seeds', value: 50 },
  //   { category: 'Fertilizers', value: 40 },
  //   { category: 'Pesticide', value: 30 },
    // Add more data points as needed
  //];
  // category: {
  //   category: string;
  //   subcategories: Subcategory[];
  // } = {
  //   category: 'License',
  //   subcategories: [
  //     { label: 'Seeds', value: 50 },
  //     { label: 'Fertilizers', value: 100 }
  //   ]
  // };
  createLicenseChart() {
    // const FPOCount = 900
    // let fertilizerLicenseCount = 700;
    // let seedLicenseCount = 300;
    // let pesticideLicenseCount = 400; // Adjust this count as needed

    let fertilizerLicenseCount = 0;
    let seedLicenseCount = 0;
    let pesticideLicenseCount = 0;
    // const data = [
    //   { label: 'Fertilizers', value: fertilizerLicenseCount },
    //   { label: 'Seeds', value: seedLicenseCount },
    //   { label: 'Pesticide', value: pesticideLicenseCount },
    // ];

    this.dashboradDonutChartLicenseData.forEach((license: any)=>{
      const licenseId = license.license_id
      if(licenseId === 1){
        seedLicenseCount += 1
      }else if (licenseId === 2){
        fertilizerLicenseCount += 1
      }else if(licenseId === 3){
        pesticideLicenseCount +=1
      }
    })

    const licenseDataArr = []
   const seedsLicenseOject = {
      label: 'Seeds',
      value: seedLicenseCount
    }
   const fertilizerLicenseOject = {
      label: 'Fertilizers',
      value: fertilizerLicenseCount
    }
   const pesticideLicenseOject = {
      label: 'Pesticide',
      value: pesticideLicenseCount
    }
    licenseDataArr.push(seedsLicenseOject, fertilizerLicenseOject, pesticideLicenseOject)

    const width = 400;
    const height = 242;
    const radius = Math.min(width, height) / 2;

   // Create color scale
   const color = d3.scaleOrdinal<string>()
   .domain(licenseDataArr.map(d => d.label))
   .range(["#E74C3C", "#7ED6DF", "#6AB04C"]);

    const pie = d3.pie<any>().value(d => d.value);
    const licenseChartContainer = this.licenseChartContainer.nativeElement;
    // Remove any existing SVG elements from the container
    d3.select(licenseChartContainer).selectAll("svg").remove();
    const svg = d3.select(licenseChartContainer)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", `translate(${radius}, ${height / 2})`);

    const arc = d3.arc<any, d3.PieArcDatum<any>>()
  .innerRadius(d => {
    if (d.data.label === 'Seeds') return radius - 80;
    if (d.data.label === 'Pesticide') return radius - 55;
    return radius - 30;
  })
  .outerRadius(d => {
    if (d.data.label === 'Seeds') return radius - 65;
    if (d.data.label === 'Pesticide') return radius - 40;
    return radius - 15;
  })
  .startAngle(0)
  .endAngle(d => d.value / this.FPOCount * Math.PI * 2);
  arc.cornerRadius(8);

  const line = d3.arc<any, d3.PieArcDatum<any>>()
  .innerRadius(d => {
    if (d.data.label === 'Seeds') return radius - 73;
    if (d.data.label === 'Pesticide') return radius - 47;
    return radius - 23;
  })
  .outerRadius(d => {
    if (d.data.label === 'Seeds') return radius - 72;
    if (d.data.label === 'Pesticide') return radius - 46;
    return radius - 22;
  })
  .startAngle(0)
  .endAngle(d => d.value / this.FPOCount * Math.PI * 2 - (2 * Math.PI));

const hoveredArc = d3.arc<any, d3.PieArcDatum<any>>()
  .innerRadius(d => {
    if (d.data.label === 'Seeds') return radius - 80;
    if (d.data.label === 'Pesticide') return radius - 55;
    return radius - 30;
  })
  .outerRadius(d => {
    if (d.data.label === 'Seeds') return radius - 60;
    if (d.data.label === 'Pesticide') return radius - 35;
    return radius - 10;
  })
  .startAngle(0)
  .endAngle(d => d.value / this.FPOCount * Math.PI * 2);
  hoveredArc.cornerRadius(8)

  const hoveredLine = line
  const dataTextOnHover = svg.append("text")
  .attr("class", "donut-data-text")
  .attr("id", "data-text")
    .attr("text-anchor", "middle")
    .attr("dy", "0.18em")
    .style("font-size", 27)
    .style("font-weight", "bold")
    .style("fill", "#333")
    .text(``);

    const FPOCountValue = this.FPOCount
    svg.selectAll('.arc')
      .data(pie(licenseDataArr))
      .enter()
      .append('path')
      .attr('class', 'arc')
      .attr('d', arc)
      .attr('d', d => {
        // Use line for the outer arc
        const outerArcPath = line(d) || '';

        // Use arc for the inner arc
        const innerArcPath = arc(d) || '';

        // Combine the paths to complete the rings
        return outerArcPath + innerArcPath;
      })
      .attr('fill', d => {
        // Set the fill color for line to gray and for arc based on the label
        if (d.value < 1) {
          return 'gray'; // Gray color for line
        } else {
          return color(d.data.label); // Color based on the label for arc
        }
      })
      .attr('opacity', 0.8)
      .on("mouseover", function (event, d: any) {
        const hoveredValue = d.value; // Get the hovered value
    const percentage = hoveredValue / FPOCountValue;

    // Get the arc and line paths with null checks
  const hoveredArcPath = hoveredArc(d) || '';
  const hoveredLinePath = hoveredLine(d) || '';
    // Transition to change arc properties
    d3.select(this)
      .attr('d', hoveredArcPath + hoveredLinePath);

        dataTextOnHover
      .text(`${d3.format(".1%")(percentage)}`)
      .style("fill", color(d.data.label)); // Update the text with the formatted percentage
  })


      .on("mouseout", function (event, d) {
        // Restore original path on mouseout
        // Get the arc and line paths with null checks
  const arcPath = arc(d) || '';
  const linePath = line(d) || '';
  d3.select(this)
  .attr('d', arcPath + linePath);
          dataTextOnHover.text('')
      });


      // Add legend
    const legendGroup = svg.append("g")
    .attr("transform", `translate(${radius + 40}, ${-radius + 20})`);

  const legend = legendGroup.selectAll(".legend")
    .data(licenseDataArr)
    .enter()
    .append("g")
    .attr("class", "legend")
    .attr("transform", (d, i) => `translate(0, ${i * 30})`);

  legend.append("circle")
    .attr("cx", 8)
    .attr("cy", 70)
    .attr("r", 6)
    .style("fill", (d) => color(d.label));

  legend.append("text")
    .attr("x", 24)
    .attr("y", 70)
    .attr("dy", "0.35em")
    .style("font-size", 14)
    .style("font-family", "Red Hat Display")
    .style("fill", (d) => color(d.label))
    .text((d) => `${d.label}`);
    // .text((d) => `${d.label} - ${d3.format(".1%")(d.value / d3.sum(data, (d) => d.value))}`);

  }

}
