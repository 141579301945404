import { Component,OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-fpo-statistics',
  templateUrl: './fpo-statistics.component.html',
  styleUrls: ['./fpo-statistics.component.css']
})
export class FpoStatisticsComponent implements OnInit{
  constructor(private dashboardServ: DashboardService){}
  analysisData: {[k: string]: any} = {}
  ngOnInit(): void {
  
    this.dashboardServ.fetchAnalysis()
    .subscribe({
      next: (responseData : any) => {
        this.analysisData = responseData.data
        
      },
      error: err=> {
        console.log(err)
      }
    })
  }
}
