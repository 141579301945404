<div class="fpo-profile-container">
  <div class="background-container">
    <div class="background-image" [style.backgroundImage]="'url(../../../../../assets/contact-details-bg-img.svg)'"></div>
  </div>
<form method="post" id="contact-details" enctype="application/x-www-form-urlencoded" action="" #contactDetails="ngForm" (ngSubmit)="onClickNext(contactDetails)">
  <!-- <div class="error-container" *ngIf="isErrorFound">{{errorMessage}}</div> -->
  <div class="form-part1">
    <div class="input-container">
      <input type="text" name="chairman" [(ngModel)] = "contactDetailObj.chairman" required>
      <span class="floating-label">Chairman<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
      <input name="chairman_phone" type="number" [(ngModel)] = "contactDetailObj.chairman_phone" required>
      <span class="floating-label">Phone<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
      <input type="text" name="key_person_name" [(ngModel)] = "contactDetailObj.key_person_name" required>
      <span class="floating-label">Name of Key Contact<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
      <input type="number" name="key_person_phone" [(ngModel)] = "contactDetailObj.key_person_phone" required>
      <span class="floating-label">Phone<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
      <input class="ex1" type="text" name="promoter_name" [(ngModel)] = "contactDetailObj.promoter_name" required>
      <span class="floating-label">Promoted By<span style="color: #FF0000;">*</span></span>
    </div>
    <div class="input-container">
      <input class="ex2" type="text" name="promoter_email" placeholder="" [(ngModel)] = "contactDetailObj.promoter_email" required>
      <span class="floating-label">email<span style="color: #FF0000;">*</span></span>
    </div>
  </div>
  <div class="form-part2">
    <div><h3>Board of Directors</h3></div>
    <div class="form-subpart" *ngFor="let boardOfDirector of boardOfDirectors; let i = index">
      <div class="input-container">
        <input type="text" [(ngModel)]="boardOfDirector.contact_name" name="dir_contact_name_{{i}}"  required>
        <span class="floating-label">Name<span style="color: #FF0000;">*</span></span>
      </div>
      <div class="input-container">
        <input type="text" [(ngModel)]="boardOfDirector.contact_phone" name="dir_phone_{{i}}" required>
        <span class="floating-label">Phone<span style="color: #FF0000;">*</span></span>
      </div>
      <div class="input-container">
        <input type="text" [(ngModel)]="boardOfDirector.contact_email" name="dir_email_{{i}}" required>
        <span class="floating-label">email<span style="color: #FF0000;">*</span></span>
      </div>
      <div class="delete-btn" (click)="onRemoveDirector(i)"><span><i class="fa-solid fa-trash-can" style="color: #60AA3F; padding-left: 5px; padding-top: 4px;"></i></span></div>
    </div>
    <div class="add-btn" (click)="addInput()"><span><i class="fa-solid fa-plus" style="color: #000000; font-size: 1rem; font-weight: 100; padding-left: 5px; padding-top: 4px;"></i></span></div>
  </div>
  <button name="next" id="next-btn">NEXT</button>
</form>
</div>
