import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FpoReportTabularComponent } from './fpo-report-tabular/fpo-report-tabular.component';
import { AdminComponent } from './admin.component';

const routes: Routes = [
  {path: 'admin', component: AdminComponent,  children: [
     {path:'', redirectTo: 'registered-fpo-report', pathMatch: 'full'},
    {path:'registered-fpo-report',component:FpoReportTabularComponent}
  ]},
  //{path: 'fpo-profile', component: FpoProfileComponent}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
