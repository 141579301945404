<div class="chart" #chart>
  <div class="bar-tooltip" [id]="'bar-tooltip' + componentId">
    <div class="state-name"><h3>{{stateName}}</h3></div>
    <div class="tooltip-info">
      <div class="fpos" *ngIf="componentId==='1'"><h4>FPOs</h4><h4>{{fpoCount1}}</h4></div>
      <div class="farmers" *ngIf="componentId==='2'"><h4>Farmers</h4><h4>{{farmerCount2}}</h4></div>
    <div class="farmers-per-fpo"><h4>Farmers/FPO</h4><h4>{{farmersPerFpo}}</h4></div>
    </div>
  </div>
</div>

