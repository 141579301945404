import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { FpoProfileService } from '../FPO-Profile/fpo-profile.service';
import { AdminService } from '../admin/admin.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css'] ,
  encapsulation: ViewEncapsulation.None
})
export class NavigationBarComponent {
isProfilePage: boolean = false
isProfileSummaryPage: boolean = false
isFPOReportPage: boolean =false
areMenuItemsShown: boolean = false
isAdmin:any = false
constructor(private fpoProfileServ: FpoProfileService, private adminServ: AdminService, private router: Router){
  this.router.events
    .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      const currentPath = event.url.split('/')[2];
      const path = event.url.split('/')[1];
      this.fpoProfileServ.setIsProfileSummaryPage(currentPath === 'profile-summary');
      this.adminServ.setisFPOReportPage(currentPath === 'registered-fpo-report');
      this.isProfilePage = (path === 'fpo-profile' && currentPath !=='profile-summary')? true: false
    });
}
isScrolled: boolean = false;
// isFixed: boolean = false;

@HostListener('window:scroll', [])
onScroll(): void {
  const navbarHeight = 132; // Adjust this value to match your actual navbar height
  const scrollY = window.scrollY;

  this.isScrolled = scrollY > 64;

  // console.log(navbarHeight)
}
ngOnInit(): void {
  const userId = window.localStorage.getItem('userId');
  this.isAdmin = window.localStorage.getItem('isAdmin');
  //added by shoeb sir breaking email verification functionality
  // if(!userId){
  //   this.router.navigate(['/auth/login'])
  // }
//   this.router.events
//       .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
//       .subscribe((event: NavigationEnd) => {
//         const currentPath = event.url.split('/')[1]; // Get the first segment of the URL
//         const currentPathLastName = event.url.split('/')[2]
//         this.isProfilePage = currentPath === '' || currentPath === 'fpo-profile';
//         this.isProfileSummaryPage = currentPathLastName === 'profile-summary'
// console.log(this.isProfileSummaryPage)
//   });

//return this.isProfilePage

}

onHoverMenuButton() {
  this.areMenuItemsShown = true
}
onLeaveMenuButton() {
  this.areMenuItemsShown = false
}
}
