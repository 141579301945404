<!-- <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sign In</title>
    <meta name="viewport" content="width=device-width" initial-scale="1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Red+Hat+Display:wght@500&display=swap" rel="stylesheet">
</head> -->
<div id="spinLoader">
  <app-loader *ngIf="isLoading"></app-loader>
</div>
<div class="auth-background-image" *ngIf="!isLoading"></div>
  <div class="auth-div" *ngIf="!isLoading">
    <div class="logo"><img src="../../../assets/logo.svg"></div>

  <div class="auth-main-container" *ngIf="!isLoading">
    <h1>Welcome Back!</h1>

  <div class="content"><span>Welcome back to AgriGation - your one stop solution for information on national agriculture statstics</span></div>
  <div class="error-container" *ngIf="!isValidCredentials">
    <span>{{error}}</span>
  </div>
    <div class="auth-form-container">
      <form #login="ngForm" (ngSubmit)="onLogIn(login)">
          <!-- Display validation errors at the top of the form -->
  <div class="client-side-error-container" *ngIf="login.invalid">
    <div *ngIf="login.controls.email.invalid && login.controls.email.errors?.required && (login.controls.email.dirty || login.controls.email.touched)">
      Email/ID is required.
    </div>
    <div *ngIf="login.controls.email.invalid && login.controls.email.errors?.pattern">
      Please enter valid Email Id.
    </div>
    <div *ngIf="login.controls.password.invalid && login.controls.password.errors?.required && (login.controls.password.dirty || login.controls.password.touched)">
      Password is required.
    </div>
    <div *ngIf="login.controls.password.invalid && login.controls.password.errors?.minlength">
      Password must be at least 8 characters long.
    </div>
    <div *ngIf="login.controls.password.invalid && login.controls.password.errors?.maxlength">
      Password cannot be longer than 12 characters.
    </div>
    <div *ngIf="login.controls.password.invalid && login.controls.password.errors?.pattern">
      Password should have atleast 1 uppercase, 1 lowercase, 1 wild character and 1 number.
    </div>
  </div>
        <div class="input-container">
          <input class="email" id="email" type="email" ngModel name="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$" required>
            <span class="floating-label">Email/ID<span style="color: #FF0000;">*</span></span>
          </div>
        <div class="input-container">
          <input class="password" id="password" type="password" ngModel name="password" minlength="8" maxlength="12" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$" required>
            <span class="floating-label">Password<span style="color: #FF0000;">*</span></span>
          </div>


        <div class="forgotpwd"><span><a routerLink="/auth/forget-password">Forgot Password?</a></span></div>
        <button id="auth-btn" name="signin" type="submit" [disabled]="login.invalid"><span class="signin">SIGN IN</span></button>

    </form>
    <div class="auth-container">
      <span class="account">Don't have an account<br></span>
    <a id="auth-link" routerLink="/auth/signup"><span id="auth-text">SIGN UP</span> </a>
    </div>
    </div>
  </div>
  </div>
<!-- </html> -->
