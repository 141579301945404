import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FpoProfileService } from '../fpo-profile.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-financial-details',
  templateUrl: './financial-details.component.html',
  styleUrls: ['./financial-details.component.css']
})
export class FinancialDetailsComponent implements OnInit {
  // isErrorFound: boolean = false
  @ViewChild('financeDetails') financeDetails !: NgForm

  completedFormSegments: boolean[] = []
  errorMessage: string = ''
  financeData: {[k: string]: any} = {}
  selectedLicenseArr:string[] = []

  constructor(private fpoProfileServ: FpoProfileService, private route: ActivatedRoute, private router: Router){}
   userId = this.route.snapshot.paramMap.get('userId');

  ngOnInit(): void {
    if(this.userId!==null){
      this.fpoProfileServ.fetchFinance(parseInt(this.userId)
      ).subscribe({
        next: (responseData : any) => {
          this.financeData = responseData.data;
          this.selectedLicenseArr = this.financeData.licenseArr;

        },
        error: err=> {console.log(err)
        this.errorMessage = err
      }
      })
    }

  }
  onClickNext(financeDetails: NgForm){
    const financialData = financeDetails.value
    const licenseArr = []
    if(financialData.Seeds){
      licenseArr.push('Seeds')
    }
    if(financialData.Fertilisers){
      licenseArr.push('Fertilisers')
    }
    if(financialData.Pesticides){
      licenseArr.push('Pesticides')
    }

    if(this.userId){
    this.fpoProfileServ.saveFinanceDetails(parseInt(this.userId), financialData.financeYear, financialData.netTurnover,licenseArr, financialData.annualIncomePerMember, financialData.revenuePerAcre,financialData.financeDetailId)
    .subscribe({
      next: resData=>{
        const segmentIndex = 3; // Replace with the appropriate segment index
        this.completedFormSegments[segmentIndex] = true;
          // Save the completion status in Local Storage with a unique key
        const localStorageKey = `completedFormSegments_${segmentIndex}`;
        localStorage.setItem(localStorageKey, JSON.stringify(this.completedFormSegments[segmentIndex]));

        this.router.navigate([`/fpo-profile/crops-produce/${this.userId}`])
      },
      error: err=>{
        console.log(err)
      }
    })
  }
  }
}
