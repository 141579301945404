import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  isScrolled: boolean = false
  // isFixed: boolean = false
  constructor(private router: Router) {}
  ngOnInit(): void {  
    const userId = window.localStorage.getItem('userId');
    const isAdmin = window.localStorage.getItem('isAdmin');
    if(isAdmin && userId)
      this.router.navigate(['/dashboard'])
    else if(userId){
      this.router.navigate(['/fpo-profile/fpo-details/',+userId])
    } else {
      this.router.navigate(['/auth/login'])
    }
  }
@HostListener('window:scroll', [])
onScroll(): void {
  const navbarHeight = 132; // Adjust this value to match your actual navbar height
  const scrollY = window.scrollY;
  // this.isFixed = scrollY>0
  this.isScrolled = scrollY > 96;
  // this.isFixed = scrollY > navbarHeight;

  
}
}
