import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { EmailVerificationSuccessComponent } from './email-verification-success/email-verification-success.component';
import { EmailVerificationFailComponent } from './email-verification-fail/email-verification-fail.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { AuthComponent } from './auth.component';
import { LogoutComponent } from './logout/logout.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';


const routes: Routes = [
  {path: 'auth', component: AuthComponent, children: [
    {path:'', redirectTo: 'signup', pathMatch: 'full'},
    {path:'login',component:LoginComponent},
    {path:'signup',component:SignupComponent},
    {path:'logout',component:LogoutComponent},
    {path:'email-verification',component:EmailVerificationComponent},
    {path:'email-verification-success',component:EmailVerificationSuccessComponent},
    {path:'email-verification-fail',component:EmailVerificationFailComponent},
    {path:'verify-account',component:VerifyAccountComponent},
    {path:'forget-password',component:ForgetPasswordComponent},
    {path:'change-password',component:ChangePasswordComponent}
  ]}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
