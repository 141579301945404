import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  baseUrl = 'https://api.bharatfpo.org/api/v1'

  constructor(private http: HttpClient) { }
  
  private isFPOReportPageSubject = new BehaviorSubject<boolean>(false);
  isFPOReportPage$ = this.isFPOReportPageSubject.asObservable();

  setisFPOReportPage(value: boolean) {
    this.isFPOReportPageSubject.next(value);
  }

  fetchFpoMembers(stateId : number){
    if(stateId)
    return this.http.get(`${this.baseUrl}/admin/fpo-members-list?state_id=${stateId}`)
    else
    return this.http.get(`${this.baseUrl}/admin/fpo-members-list`)
  }
}
